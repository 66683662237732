/* Import variables */
@import url('variables.css');

/* Remove inner focus styles for buttons and inputs in Firefox */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.progress-bar-container {
  background-color: #f3f3f3;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
}

.progress-bar {
  background-color: #4caf50;
  height: 100%;
  border-radius: 10px;
}

.certificate-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 24px;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.certificate-btn:hover {
  background-color: #218838;
}

/* Styl dla przycisku "Edytuj" jako przycisk drugorzędny z mniejszą widocznością */
.certificate-btn.secondary {
  margin: 10px; /* Margines */
  padding: 10px 20px; /* Wypełnienie */
  border: 1px solid #ddd; /* Jaśniejsze obramowanie */
  border-radius: 10px; /* Zaokrąglenie rogów */
  background-color: rgba(255, 255, 255, 0.7); /* Przezroczyste tło */
  color: rgba(0, 0, 0, 0.7); /* Mniej intensywny kolor tekstu */
  cursor: pointer; /* Kursor wskazujący */
  font-size: 14px; /* Mniejszy rozmiar czcionki */
  box-shadow: none; /* Brak cienia */
  transition: background-color 0.3s ease, color 0.3s ease; /* Płynne przejścia */
}

/* Stan po najechaniu dla przycisku drugorzędnego */
.certificate-btn.secondary:hover {
  background-color: rgba(240, 240, 240, 0.8); /* Jaśniejszy efekt po najechaniu */
  color: rgba(0, 0, 0, 0.9); /* Nieco ciemniejszy kolor tekstu */
}

/* Stan focus - usunięcie niebieskiej obramówki */
.certificate-btn.secondary:focus {
  outline: none; /* Brak domyślnej ramki */
  /* border-color: #007bff; */ /* Usunięto niebieską obramówkę */
}

/* Stan wyłączony */
.certificate-btn.secondary:disabled {
  background-color: #f5f5f5; /* Jaśniejsze tło */
  color: #ccc; /* Szary kolor tekstu */
}

/* Admin options container */
.adminoptions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  background-color: var(--text-color);
  padding: 10px 0;
  z-index: 10;
}
.modal a {
   color:var(--text-color);
}
/* Modal styles */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  background: var(--desktop-info-background);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  overflow-y: auto;
}

.modalcontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* Close button styles */
.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

/* Desktop info box styles */
.desktop-info {
  position: absolute;
  display: none;
  top: 50px;
  right: 20px;
  margin: 20px;
  padding: 20px;
  width: 200px;
  background-color: var(--desktop-info-background);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.desktop-info h2 {
  color: #333;
}

.desktop-info p {
  color: #555;
  font-size: 16px;
  line-height: 1.5;
}

/* Shortcut button styles */
.shortcut-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100px;
  background: var(--button-background);
  color:var(--button-text-color);
  border-radius: 12px;
  margin: 15px 0;
  position: relative;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.shortcut-button.editing {
  border: 2px dashed #007bff;
  box-shadow: none;
  transform: none;
}

.shortcut-button:hover {
  transform: scale(1.05);
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

/* Icon and text styles within shortcut button */
.shortcut-button .icon {
  margin-right: 12px; /* Spacing between icon and text */
  font-size: 48px; /* Larger icon size */
  color: #007bff;

}

.shortcut-button .text {
  display: flex;
  align-items: center;
  font-size: 24px; /* Larger text size */
  color:var(--button-text-color);
}
.shortcut-button a {
  color:var(--button-text-color);
}
.shortcut-button span {
  font-size: 25px; 
   margin: 10px;
}
/* Delete button styles */
.delete-btn {
  background: none;
  border: none;
  color: #ff4d4d;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  transition: color 0.2s;
}

.delete-btn:hover {
  color: #cc0000;
}

/* Shortcut link styles */
.shortcut-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

/* Button styles */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.modal .button a,
.modal .button button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px !important;
  height: 50px;
  border: none;
  text-decoration: none;
  color: var(--button-text-color);
  background-color: var(--ui_background);
  margin: 0;
  text-overflow: ellipsis;
}

.button button {
  padding: 0;
}

.button p {
  margin: 0;
  font-size: 20px;
  text-align: right;
  flex: 1;
}

.button a span,
.button button span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.button a svg,
.button button svg {
  width: 30px;
  height: 30px;
}

/* Link styles */
a {
  text-decoration: none;
}

/* Icon sizes */
.home,
.scan,
.searchbtn {
  width: 30px;
  height: 30px;
  padding: 4px;
}

/* Container styles */
.shortcuts-container {
  width: 90%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: 20px;
  position: relative;
  z-index: 1;
}

/* Styl dla paska przewijania */
.shortcuts-container::-webkit-scrollbar {
  width: 8px; /* Szerokość paska przewijania */
}

.shortcuts-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Tło paska przewijania */
}

.shortcuts-container::-webkit-scrollbar-thumb {
  background: #888; /* Kolor uchwytu paska przewijania */
  border-radius: 10px; /* Zaokrąglenie uchwytu */
}

.shortcuts-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Kolor uchwytu po najechaniu */
}

/* Responsive styles */
@media only screen and (max-width: 767px) {
  .button-section {
    width: 90%;
  }
}

@media only screen and (max-width: 740px) {
  .infohome {
    width: 95%;
    transform: translate(-50%, -55%);
  }
}

/* Tablet-specific styles */
@media only screen and (min-width: 768px) and (orientation: portrait) and (hover: none) and (pointer: coarse) {
  .infohome,
  .modal {
    width: 90%;
    height: auto;
  }

  .button-section {
    width: 100%;
  }
}

/* Desktop-specific styles */
@media only screen and (min-width: 1025px) {
  .infohome,
  .modal {
    width: 80%;
  }

  .barcode-section {
    display: none;
  }

  .desktop-info {
    display: block;
  }
}

/* Styl dla popupu ze skrótami */
.shortcut-popup {
  background-color: white; /* Tło popupu */
  border-radius: 10px; /* Zaokrąglenie rogów */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Cień dla efektu uniesienia */
  padding: 20px; /* Wypełnienie */
  max-width: 400px; /* Maksymalna szerokość */
  margin: auto; /* Wyśrodkowanie */
  transition: transform 0.3s ease; /* Płynne przejście */
}

/* Styl dla nagłówka popupu */
.shortcut-popup h2 {
  font-size: 20px; /* Rozmiar czcionki nagłówka */
  margin-bottom: 15px; /* Margines dolny */
}

/* Styl dla przycisków w popupie */
.shortcut-popup .button {
  margin: 5px 0; /* Margines dla przycisków */
  width: 100%; /* Szerokość przycisków */
}

/* Styl dla przycisku zamknięcia */
.shortcut-popup .close-button {
  background-color: transparent; /* Przezroczyste tło */
  color: #ff0000; /* Czerwony kolor tekstu */
  border: none; /* Brak obramowania */
  cursor: pointer; /* Kursor wskazujący */
  font-size: 16px; /* Rozmiar czcionki */
  position: absolute; /* Pozycjonowanie */
  top: 10px; /* Odległość od góry */
  right: 10px; /* Odległość od prawej */
}
