/* styles.css */

/* General Details Container Styles */
.details-vc-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: rgb(18, 16, 16);

    /* Light background color */
    /* Light border for separation */
}
.apply-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto;
}
.mod {
    gap:100px;
}
.mod-img {
    width:300px;
    height:300px;
    border-radius: 5px;
}
.video-player {
    width: 452px; /* Fixed width */
    height: 650px; /* Fixed height */
    border-radius:10px;
  }

.appimg {
    width: 452px; /* Fixed width */
    height: 650px; /* Fixed height */
    border-radius:10px;
}

.dt-vc-text-section {
    max-width: 50%;
    padding: 20px;
    margin-right: 15%; /* Space between text and image */
}

.details-vc-title {
    margin-right: 0 !important;
}

.details-vc-title h1 {
    font-size: 3.5rem; /* Adjust size as needed */
    margin: 0;
}

.details-vc-title .dc {
    color: #007bff; /* Blue color for DiabCalc */
    font-size: 3.5rem; /* Larger size for emphasis */
}

.details-vc-description {
    font-size: 1.5rem; /* Subheading text size */
    margin: 0 0 20px;
}


  
/* Responsive Styles for Tablets */
@media (max-width: 768px) {
    .details-vc-container {
        flex-direction: column;
        align-items: center;
        padding: 15px;
        text-align: center;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }

    .video-player {
        width: 100%; /* Fixed width */
        height: 100%; /* Fixed height */
    
        align-self: center;
      }
    
   
    .dt-vc-text-section {
        max-width: 90%;
        text-align: center;
        padding: 10px;
        margin: 0 auto;
        word-break: break-word;
        overflow-wrap: break-word;
    }
.dt-vc-image-section {
    width: 80%; /* Fixed width */
    height: 250px; /* Fixed height */
    border-radius:10px;
    padding:10px;
    margin: 0 auto;
}
    .appimg {
    /* Make the image responsive */
        height: 650px; /* Maintain aspect ratio */
        max-width: 450px; /* Restrict maximum width */
        display: block;
        margin: 0 auto;
    }

    .details-vc-title h1 {
        font-size: 2.5rem;
    }

    .details-vc-title .dc {
        font-size: 3rem;
    }
  
    .details-vc-description {
        font-size: 1.1rem;
        line-height: 1.5;
        margin: 15px auto;
        max-width: 90%;
        white-space: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }

    .apply-button {
        margin: 15px auto;
        width: 80%;
        max-width: 250px;
    }
}

