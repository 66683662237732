


.iconlanding {
  font-size: 15px;
}

.landing-page {
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  /* background:linear-gradient(309deg, rgb(39, 123, 201) 27%, rgba(135,182,222,1) 49%, rgba(245,245,245,1) 79%,rgb(39, 123, 201) 87%); */
  background: url('cukrzyca.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: rgb(18, 16, 16);
}
.imgks {
  width: 200px; /* dowolna wartość szerokości */
  height: 200px; /* ustalona szerokość, aby uzyskać kwadratowe zdjęcie */
  border-radius:10px; /* promień zaokrąglenia */
  object-fit: cover;
  align-self: center; /* zapewnia, że zdjęcie będzie wypełniać całe pole, zachowując proporcje */
}
.link {
  text-decoration: none;
  color:blue;
}
.social-media {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-link {
  background-color: white;
  color: black;
  display: flex; /* Ustawia kontener na flexbox */
  align-items: center; /* Wyśrodkowuje zawartość pionowo */
  justify-content: center; /* Wyśrodkowuje zawartość poziomo */
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius:10px;
  transition: background-color 0.3s, color 0.3s;
}

.social-link:hover {
  background-color: #007bff; /* Adjust this color as needed */
  color: white;
}

.social-link span {
  display: flex;
  align-items: center;
  justify-content: center;
text-align: center;
 font-size: 30px;
}

.social-link svg {
  align-items: center;

}
.cookie-consent {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background-color: #3498dbc0;
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 20px;
  border-radius:10px;;
  text-align: center;
  z-index: 1000;
}

.cookie-consent button {
  background-color: #007bff; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius:10px;
}


.landinginfo {
 
  overflow-y: auto;
  margin: 160px auto;
  width: 90vw;

  background: #fff;
  height: auto;
  padding: 20px;
  border-radius:10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: grid;
}

.top-bar {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  position: fixed; /* Make the top bar fixed at the top */
  top: 0; /* Align the top bar to the top of the window */
  left: 0; /* Align the top bar to the left side of the window */
  width: 100vw; /* Make the top bar span the full width of the window */
  z-index: 1000; /* Ensure the top bar is above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add subtle shadow for a professional look */
}

.centertopbar {
  display: flex;
  justify-content: space-between; /* Space out items within centertopbar */
  align-items: center;
  width: 80vw; /* Set the width of the content */
}

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.logolanding {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.diabcalc-title {
  font-size: 1.5rem;
  font-weight: bold;
}



.nav-links a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  transition: color 0.3s;
}





.menu-icon {
  display: none; /* Hide menu icon by default */
  font-size: 1.5rem;
  cursor: pointer;
}



.diabcalc-image {
  width: 200px;
}
.selectlanguage{
  background-color: white;
  color: black;
}

.landing-title {
  font-size: 32px;
  margin-bottom: 20px;
}

.downloadtitle {
  font-size: 20px;
  margin-bottom: 20px;
  align-self: center;
  text-align: center;
  font-weight: bold;
}

.landing-description {
  font-size: 18px;
}

.features-section {
  text-align: center;
  padding: 50px 20px;
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.features-image {
  width: 100%;
  margin-bottom: 20px;
}

.features-description {
  font-size: 18px;
}

.landing-nav {
  text-align: center;
  margin-top: 30px;
}

.landing-link {
  margin: 0 10px;
  font-size: 18px;
}

.landing-footer {
  background-color: #3498db;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

.footer-image {
  width: 200px;
  margin-bottom: 20px;
}

.footer-text {
  font-size: 16px;
}


.zwolnieni {
  text-decoration: none;
  color: rgb(255, 0, 255);
  font-weight: bold;
}






/* Navbar styles */



/*

  display: flex;
  justify-content: center; 
  align-items: center;
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%; 
  z-index: 1000;




*/

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color:white;
}

.logolanding {
  width: 40px;
  margin-right: 10px;
}

.diabcalc-title {
  font-size: 24px;
 
}


.nav-links open {
  display: flex;
  gap: 20px;
}

.logolanding {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius:10px;;
}
.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}
.nav-links a.active {
  background-color: #007bff; /* Adjust this color as needed */
  color: white;
  border-radius:10px;

}

.img {
  width:70%;
  height:70%;
}
@media (max-width: 768px) {


  .landing-title {
    font-size: 24px; /* Reduce font size for mobile */
    text-align: center; /* Center the title */
  }

  .downloadtitle {
    font-size: 18px; /* Adjust text for mobile */
  }

  .landing-description, .features-description {
    font-size: 16px; /* Make the text slightly smaller for readability */
  }

  .features-section {
    padding: 30px 10px; /* Adjust padding to fit better on smaller screens */
  }

  .features-image {
    width: 90%; /* Ensure the image doesn’t overflow the screen */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center image on the page */
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    left: -100%;
    background-color: #3498db;
    width: 100vw;
    height: 100%;
    transition: left 0.3s ease;
    z-index: 10;
    font-size: 1.2rem; /* Make the text slightly larger for mobile navigation */
    padding-top: 20px;
  }

  .nav-links a {
    padding: 10px 20px;
    font-size: 1.5rem;
  }

  .menu-icon {
    display: block; /* Ensure menu icon shows on mobile */
  }

  .top-bar {
    padding: 10px;
    font-size: 1.2rem;
  }

  .logolanding {
    height: 30px; /* Adjust logo size */
  }

  .social-media {
  /* Stack social media icons */
    gap: 15px;
  }

  .cookie-consent {
    bottom: 10px; /* Adjust the position */
    padding: 15px;
  }
}
/* General styles */
.nav-links {
  display: flex; /* Default flex behavior for desktop */
  gap: 20px;
}

.menu-icon {
  display: none; /* Hide menu icon on desktop */
  font-size: 24px;
  cursor: pointer;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.nav-links a.active {
  background-color: #007bff;
  color: white;
  border-radius: 10px;
}

/* Updated Mobile Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide nav-links by default on mobile */
    flex-direction: column;
    position: fixed;
    top: 60px;
    left: -100%; /* Initially hidden off-screen */
    background-color: #3498db;
    width: 100vw;
    height: 100%;
    transition: left 0.3s ease; /* Animation for sliding in */
    z-index: 10;
    font-size: 1.2rem;
    padding-top: 20px;
  }

  .nav-links.open {
    left: 0; /* Slide in the nav-links when open */
    display: flex;
  }

  /* Animation for the menu icon */
  .menu-icon {
    display: block;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease; /* Add rotation animation */
  }

  /* Rotate icon when menu is open */
  .menu-icon.open {
    transform: rotate(90deg); /* Rotate 90 degrees when menu is open */
  }

  /* Adjust top-bar and logo size for mobile */
  .top-bar {
    padding: 10px;
    height: 5%;
    font-size: 1.2rem;
  }

  .logolanding {
    height: 40px;
    width: 40px;
  }
}

/* OurMission.css */
.mission-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
}

.mission-image-section {
  flex: 1;
  display: flex;
  justify-content: center;
}

.mission-img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}

.mission-text-section {
  flex: 2;
  text-align: center;
  padding: 20px;
}

.mission-title h1 {
  font-size: 2.5em;
  color: #333;
}

.mission-description {
  font-size: 1.2em;
  color: #666;
  margin-top: 10px;
}

.mission-logo-section {
  flex: 1;
  display: flex;
  justify-content: center;
}

.nasa-logo {
  width: 100px;
  height: auto;
}

/* Dodatkowe style responsywne */
@media (max-width: 1024px) {
  .mission-container {
    flex-direction: column;
    padding: 10px;
  }

  .mission-text-section {
    flex: 1;
    padding: 15px;
  }

  .mission-title h1 {
    font-size: 2em;
  }

  .mission-description {
    font-size: 1em;
  }

  .mission-img {
    max-width: 100%;
    margin: 10px 0;
  }
  .landinginfo {
    display: flex; /* Umożliwia użycie flexboxa */
    flex-direction: column; /* Ustawia elementy w kolumnie */
    align-items: center; /* Wyśrodkowuje elementy w poziomie */
    justify-content: center; /* Wyśrodkowuje elementy w pionie */
    max-width: 600px; /* Ustala maksymalną szerokość sekcji */
    margin: 120px auto; /* Wyśrodkowuje sekcję */
    padding: 20px; /* Dodaje padding, aby zawartość nie przylegała do krawędzi */
  }
  
  .landing-info > * {
    width: 100%; /* Umożliwia elementom zajmowanie pełnej szerokości sekcji */
    margin: 10px 0; /* Zmniejsza margines, aby uniknąć nadmiaru przestrzeni */
    box-sizing: border-box; /* Umożliwia lepsze zarządzanie szerokością z paddingiem */
  }
  
}

@media (max-width: 480px) {
  .social-media {
    flex-wrap: wrap;
    justify-content: center;
  }

  .social-link {
    width: 40px;
    height: 40px;
  }

  .social-link span {
    font-size: 24px;
  }

  .landing-title {
    font-size: 20px;
  }

  .mission-title h1 {
    font-size: 1.8em;
  }

  .imgks {
    width: 150px;
    height: 150px;
  }
}
