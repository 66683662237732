/* Container for the entire camera interface */
.camera-interface-container {
  position: relative;
  width: 100%;
  height: 20%;
   /* Gradient background for depth */
  border-radius: 20px; /* Rounded corners for a modern look */
  overflow: hidden; /* Hide overflow for a clean look */
}

.camera-container {
  position: relative;
  width: 100%;
  height: 100%; /* Ensure this fills the parent container */
  overflow: hidden; /* Hide overflow */
}

/* Adjusted camera controls at the top */
.camera-controls {
  position: absolute;
  top: 20px; /* Positioning at the top */
  left: 50%;
  transform: translateX(-50%); /* Center the controls horizontally */
  display: flex;
  justify-content: space-around;
  width: 90%; /* Wider to accommodate more buttons */
  pointer-events: none; /* Allow clicks to pass through the container */
  z-index: 1000;
}

.camera-controls a {
  pointer-events: auto; /* Make the buttons clickable */
}

/* Individual buttons inside the container */

/* Styles for each control button */
.control-button {
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  border: 2px solid rgba(255, 255, 255, 0.5); /* Semi-transparent border */
  color: #fff;
  border-radius: 12px; /* Slightly larger border radius */
  width: 70px; /* Increased size for better usability */
  height: 70px;
  font-size: 20px; /* Slightly larger font size */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.2s; /* Add scaling effect */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Enhanced shadow for depth */
}

/* Style for active control button */
.control-button.active {
  background-color: rgba(255, 255, 255, 0.3); /* Active background */
  transform: scale(1.05); /* Slightly enlarge when active */
}

/* Hover effect for control buttons */
.control-button:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Lighter background on hover */
  transform: scale(1.1); /* Enlarge on hover for interaction feedback */
}

/* Styles for the capture button */
.capture-button {
  background: linear-gradient(90deg, #ff6b6b, #f7d94c); /* Gradient for the capture button */
  border: none; /* No border */
  color: #fff;
  border-radius: 50%; /* Circular shape */
  width: 90px; /* Larger size for prominence */
  height: 90px;
  font-size: 24px; /* Larger font for visibility */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s, box-shadow 0.3s; /* Add transitions */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Shadow for depth */
}

/* Hover effect for capture button */
.capture-button:hover {
  transform: scale(1.1); /* Enlarge on hover */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
}

/* Optional: Additional styling for better cohesion */
.camera-controls a {
  text-decoration: none; /* Remove underline from links if used */
}
