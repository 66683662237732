@import url('variables.css');
.product-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  max-height: 80%; /* Maksymalna wysokość popupu, 80% wysokości ekranu */
  overflow-y: auto; /* Włącza przewijanie w pionie, gdy zawartość jest zbyt długa */
  background: var(--desktop-info-background);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1080 !important; /* Wyższy z-index, aby popup pojawił się nad innymi elementami */
}

.scannertxt {
  text-align: center;
  font-size: 30px;
}

.text{
  font-size: 10px;
  color:var(--text-color);
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.addbtn {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius:10px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block; /* Dodano, aby przycisk był blokiem */
  margin-top: 20px; /* Dodano, aby uzyskać odstęp od innych elementów */
}


.product-popup table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.product-popup th, .product-popup td {
  border: 1px solid var(--border-color);
  padding: 8px;
  text-align: left;
}

.product-popup th {
  background-color: var(--header-background);
  color: var(--header-text-color);
}

.target {
  z-index:1;
}
.product-popup img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius:10px;
}
@keyframes pop-up {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pop-up {
  animation: pop-up 0.3s ease forwards; /* Animacja skali i przezroczystości */
}

.errormessage {
  color:red;
  text-decoration: double;
  background-color: var(--background-color);
  z-index: 3;
  position: fixed;
  border-radius:10px;
  font-size: large;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.scanning-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 50%;
  border: 4px solid blue;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 2;
}
/* Zoptymalizowane style dla skanera */
.scanning-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 50%;
  border: 4px solid rgba(0, 0, 255, 0.6); /* Subtelniejszy niebieski */
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 2;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3); /* Lekko przezroczyste tło dla lepszej widoczności */
}

/* Zoptymalizowane obrazki produktów */
.product-popup img {
  width: 100%;
  height: auto;
  max-height: 150px; /* Zmniejszenie maksymalnej wysokości */
  object-fit: cover;
  border-radius: 8px; /* Drobna zaokrąglona krawędź */
  margin-bottom: 15px; /* Przestrzeń poniżej obrazków */
  transition: transform 0.3s ease-in-out; /* Dodanie animacji powiększenia */
}

.product-popup img:hover {
  transform: scale(1.05); /* Powiększenie obrazu po najechaniu */
}

/* Zoptymalizowanie tekstów w popupie */
.product-popup .scannertxt {
  font-size: 26px; /* Zmniejszenie rozmiaru czcionki */
  font-weight: 600; /* Wyróżnienie nagłówka */
  color: var(--primary-text-color); /* Użycie koloru głównego tekstu */
}

.product-popup .text {
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 15px; /* Przestrzeń pod tekstem */
}

/* Przycisk dodawania produktu */
.addbtn {
  margin: 15px auto;
  padding: 12px 25px;
  border: none;
  border-radius: 10px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin-top: 20px;
}

.addbtn:hover {
  background-color: var(--button-hover-background); /* Dodanie efektu hover */
}
