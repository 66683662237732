/* Import variables */
@import url('variables.css');

/* Main container */
.container {

  color: var(--text-color);
  height: 75vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
}


.no-meals {
  text-align: center;
  font-size: 18px;
  color: var(--text-muted);
  margin-top: 20px;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ui_background);

  padding: 10px 15px;
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.title {
  font-size: 1.5rem;
  color: white;
  font-weight: bold;
}

.add-button {
  background-color: var(--button-background);
  color: var(--button-text-color);
  border: none;
  border-radius: 20%;
  width: 50px;
  height: 50px;
  font-size: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.click {
  width:100%;
  height:69%;
}
.add-button-list {
  background-color: var(--button-background);
  color: var(--button-text-color) !important;
  border: none;
  border-radius: 20%;
  width: 50px;
  height: 50px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.add-button:hover {
  background-color: var(--nav-link-bg-active);
  transform: scale(1.1);
}

/* Meal list */
.meal-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  width: 100%;
  max-width: 800px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.meal-card {
  background-color: var(--desktop-info-background);
  color: var(--desktop-info-text-color);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  border: 1px solid var(--border-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}

.meal-card:hover {
  background-color: var(--ui_background);
  color: var(--text-color);
  transform: translateY(-5px);
}

.meal-name {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.texticon {
  font-size: 10px;
  color: var(--wwcolor);
}

.meal-info {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meal-carbs {
  font-size: 1.25rem;
  color: var(--wwcolor);
}

.meal-more {
  color: var(--button-background);
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.meal-more:hover {
  color: var(--nav-link-bg-active);
}

/* Modal styles */
.add-meal-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000060;
  color:var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.add-meal-modal-content {
  background-color: var(--desktop-info-background);
  padding: 25px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.add-meal-modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.add-meal-modal form {
  background-color: var(--desktop-info-background);
  box-shadow:none;
}

.add-meal-modal input {
  margin: 10px 0;
  padding: 12px;
  border: none;
  color: var(--text-color);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(9, 177, 255, 0.1);
}
.delete-button {
  background-color: red;
  color: var(--text-color);
  border-radius: 15px;
  border: none; /* Remove default border */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: inherit; /* Inherit font size from parent */
  cursor: pointer; /* Change cursor on hover */
  outline: none; /* Remove outline */
  text-align: center; /* Center text */
}

/* Optionally, add hover effects */
.delete-button:hover {
  opacity: 0.8; /* Slightly fade on hover */
}

.add-meal-modal button {
  margin-top: 10px;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-meal-modal button:hover {
  background-color: var(--nav-link-bg-active);
  transform: translateY(-2px);
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .container {
    padding: 2rem;
  }

  .header {
    width: 100%;
    max-width: 800px;
  }

  .meal-list {
    gap: 20px;
    max-height: calc(100vh - 200px);
  }

  .meal-card {
    max-width: calc(33.333% - 20px);
  }
}

@media (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .header {
    width: 90%;
  }

  .meal-list {
    gap: 10px;
    max-height: calc(100vh - 160px);
  }

  .meal-card {
    max-width: 100%;
  }
}

.generate-button {
  min-height: 40px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.generate-button:disabled {
  background: linear-gradient(90deg, 
    rgba(0,0,255,1) 0%, 
    rgba(0,255,255,1) 25%, 
    rgba(0,255,0,1) 50%, 
    rgba(255,255,0,1) 75%, 
    rgba(255,0,0,1) 100%
  );
  background-size: 400% 400%;
  animation: gradientAnimation 3s ease infinite;
  border: none;
  color: white;
  cursor: not-allowed;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* Popraw kontrast tekstu podczas animacji */
.generate-button:disabled .loading-dots .dot {
  background: white;
  opacity: 0.9;
}
