/* Import global variables */
@import url('variables.css');

.recipe {
    color: darkgoldenrod;
    font-size: 1.2em;
    padding: 5px 10px;
    background-color: rgba(184, 134, 11, 0.1);
    border-radius: 5px;
    margin: 5px 0;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
}

/* Container for product search */
.product-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
    color: var(--text-color);
    box-sizing: border-box;
    position: relative;

}
.search-link {
    background-color: var(--button-background);
    color: rgb(255, 255, 255);
    display: flex; /* Ustawia kontener na flexbox */
    align-items: center; /* Wyśrodkowuje zawartość pionowo */
    justify-content: center; /* Wyśrodkowuje zawartość poziomo */
    text-align: center;
    border: none;
    width: 50px;
    height: 50px;
    border-radius:10px;
    transition: background-color 0.3s, color 0.3s;
    font-size: 24px; /* Increased icon size */
}
.search-link ion-icon {
    font-size: 28px; /* Make icons bigger */
    width: 28px;
    height: 28px;
}
.search-link span{
    font-size: 30px;
}
.suggestions {
    position: absolute;
    top: 160px; /* Adjust this value to align the suggestions properly under the input */
    width: 80%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    color: var(--text-color);
    z-index: 1000;
    border-radius:10px;
    margin: 0 auto;
    list-style-type:none;
}

.suggestions li {
    padding: 10px;
    cursor: pointer;
}

.suggestions li:hover {
    background-color: var(--nav-link-bg-active);
}

/* Search input and button */
.search {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--border-color);
    margin: 10px;
    padding: 20px;
    width: 80%;
    
    background: var(--desktop-info-background);
    border-radius:10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.product-search input {
    margin: 10px;
    padding: 10px;
    width: calc(100% - 20px);
    border: none;
    color: var(--text-color);
    border-radius:10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(9, 177, 255, 0.1);
}
.text-search {
    width:32px;
    height:32px;
}
.text-search-info {
    font-size: 1vw; /* Użycie mniejszej jednostki viewport width */
    width: 80%;
    height: 2em; /* Zmniejszenie wysokości do mniejszych jednostek */
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-wrap: break-word;
}
.table-item {
     /* Użycie jednostki viewport width dla responsywności */
    width: 80%; /* Dostosowanie szerokości do 80% rodzica */
    height: 3em; /* Automatyczna wysokość dla elastyczności */



 

}
.searchclick {
    width:fit-content;
}

.product-search .search button, .product-search .searchclick button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius:10px;
    background-color: var(--button-background);
    color: var(--button-text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    min-height: 42px; /* Dodaj minimalną wysokość dla przycisku */
    min-width: 100px; /* Dodaj minimalną szerokość dla przycisku */
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-search .search button:disabled {
    background-color: var(--button-background);
    opacity: 0.7;
    cursor: not-allowed;
}

.product-search button:hover {
    background-color: #0056b3;
}

/* Product list container */
.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 300px));
    gap: 15px;
    padding: 15px;
    width: 100%;
    height: auto; /* Ensure the height is auto to fit all content */
    overflow-y: auto;
    padding-bottom: 100px; /* Add padding to the bottom */
    background: rgba(255, 255, 255, 0);

}
.spacer {
   display: block; /* Position absolutely within .product-list */
   
   
    height: 68em;
    width:50px; /* Space to allow for extra scroll padding */

    pointer-events: none; /* Ignore any pointer events */
}

/* Individual product item */
.product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--text-color);
    margin: 10px;
    padding: 10px;
    height:fit-content;
    width: calc(100% - 20px);
    border-radius:10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
   background: var(--desktop-info-background) !important;
    color: var(--text-color);
}

.notfound {
    align-items: center;
    border: 1px solid var(--text-color);
    align-self: center;
    padding: 10px;
    margin: 10px;
    width: 80%;
    max-width: 600px; /* Dodanie maksymalnej szerokości, aby element nie był zbyt szeroki */
    border-radius:10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background: var(--desktop-info-background);
    color: var(--text-color);
    justify-self: center; 
    text-align: center;/* Wyśrodkowanie elementu w kolumnie siatki */
}
@keyframes pop-up {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .pop-up {
    animation: pop-up 0.3s ease forwards; /* Animacja skali i przezroczystości */
  }
  



/* Placeholder for no product information */
.infonone {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    padding: 10px;
    height: auto;
    width: calc(100% - 20px);
    border-radius:10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--button-background);
    font-size: 15px;
    color: var(--text-color);
}

.product-name-search {
    font-size: 1vw;
    width: 80%;
    height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-wrap: break-word;
}
.bold {
    font-weight: bold;
}
.infonone a {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    border-radius:10px;
    background-color: #4adb11;
    color: var(--text-color);
    text-decoration: none;
}

.product-item img {
    width: 250px;
    height: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius:10px;
}

.product-item table {
    width: 100%;
    text-align: left;
    margin-top: 10px;
}

.product-item table th, .product-item table td {
    padding: 5px;
}

/* Responsive design for iPad landscape */
@media only screen and (min-width: 768px) and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    .product-search {
        padding: 20px;
    }
 .search {
        width: 80%;
    }
    .product-name-search {
        font-size: 20px;
    }
    .text-search-info{
        font-size: 20px;
    }
   
    .table-item{
        font-size: 20px;
    }

    .product-search input {
        width: calc(100% - 30px);
    }

    

    .product-list {
        display: grid;
      
        gap: 15px;
        padding: 15px;
      
    }

    .product-item {
        width: 90%;
    }
}

/* Responsive design for iPad portrait */
@media only screen and (min-width: 768px) and (orientation: portrait) and (hover: none) and (pointer: coarse) {
    .product-search {
        padding: 15px;
        font-size: 20px;
    }
    .table{
        align-self: center;
       width:80% !important;

    }
    .search {
        width: 90%;
        margin: auto;
    }
.product-name-search {
    font-size: 20px;
}
.text-search-info{
    font-size: 20px;
}
.table-item{
    font-size: 20px;
    width:30px;
}
    .product-search input {
        width: calc(100% - 20px);
    }

    .product-list {
        padding: 10px;
    }

    .product-item {
        width: 80% ;
        align-items: center;
    
         /* Zmniejszenie czcionki na telefonie */
    }

    .product-item img {
        width: 300px; /* Zmniejszenie szerokości obrazka */
        height: 250px; /* Zmniejszenie wysokości obrazka */
    }

    .suggestions {
        top: 100px; /* Zmniejszenie odległości od góry */
        width: 90%;
    }

}
/* Responsive design for desktop */
@media only screen and (min-width: 1025px) and (hover: hover) and (pointer: fine) {
    .product-search {
        padding: 40px;
    }
.suggestions{
    width:80%;
}
    .search {
        width: 50%;
    }

    .product-search input {
        width: calc(100% - 40px);
    }

    .product-list {
        display: grid;
     
        gap: 15px;
        padding: 30px;
        width: 100%;
      
    }

    .product-item {
        width: calc(100% - 20px);
    }
}

/* Responsive design for phone landscape */
@media only screen and (max-width: 900px) and (orientation: landscape) {
    .product-search {
        padding: 15px;
    }

    .search {
        width: 90%;
        margin: auto;
    }

    .product-search input {
        width: calc(100% - 20px);
    }

 .link {
font-size: 50px;
    color:white;
 }

    .product-list {
        padding: 10px;
    
      
    }
    .product-name-search {
        font-size: 20px;
    }
    .text-search-info{
        font-size: 20px;
    }
.table-item{
    font-size: 20px;
}

    .product-item {
        width: 100%;
        font-size: 0.75vw; /* Zmniejszenie czcionki na telefonie */
    }

    .product-item img {
        width: 200px; /* Zmniejszenie szerokości obrazka */
        height: 150px; /* Zmniejszenie wysokości obrazka */
    }

    .suggestions {
        top: 100px; /* Zmniejszenie odległości od góry */
        width: 90%;
    }
}

/* Responsive design for phone portrait */
@media only screen and (max-width: 900px) and (orientation: portrait) {
    .product-search {
        padding: 15px;
        font-size: 20px;
    }

    .search {
        width: 90%;
        margin: auto;
    }
.product-name-search {
    font-size: 20px;
}
.text-search-info{
    font-size: 20px;
}
.table-item{
    font-size: 20px;
}
    .product-search input {
        width: calc(100% - 20px);
    }

  

    .product-list {
        padding: 10px;
    }

    .product-item {
        width: 100%;
         /* Zmniejszenie czcionki na telefonie */
    }

    .product-item img {
        width: 200px; /* Zmniejszenie szerokości obrazka */
        height: 150px; /* Zmniejszenie wysokości obrazka */
    }

    .suggestions {
        top: 100px; /* Zmniejszenie odległości od góry */
        width: 90%;
    }
}
.search-view-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .view-toggle {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .toggle-view-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggle-view-btn:hover {
    background-color: #0056b3;
  }
  
  .simple-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 15px;
    height: calc(100vh - 150px);
    overflow-y: auto;
    padding-bottom: 70px;
    width: 100%;
    max-width: 1400px; /* Dodane ograniczenie szerokości */
    margin: 0 auto; /* Wycentrowanie kontenera */
  }
  
  .simple-product-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 220px;
    text-align: center;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .simple-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .simple-product-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .simple-product-info {
    text-align: left;
  }
  
  .simple-product-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .simple-product-info p {
    font-size: 14px;
    margin: 5px 0;
  }
  


.info-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.info-popup h2 {
    margin: 0 0 10px;
}

.info-popup button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--button-background);
    color: var(--button-text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.info-popup button:hover {
    background-color: #0056b3;
}

.simple-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 15px;
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding-bottom: 70px; /* Add extra space for overflowing content */
  justify-items: center; /* Center items horizontally */
  align-content: flex-start; /* Start from top */
  width: 100%;
  max-width: 1400px; /* Dodane ograniczenie szerokości */
  margin: 0 auto; /* Wycentrowanie kontenera */
}

.simple-card {
  width: 100%; /* Zmiana z fixed width na 100% */
  max-width: 280px; /* Maksymalna szerokość karty */
  margin: 0 auto; /* Wycentrowanie karty */
  height: fit-content;
  min-height: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: var(--desktop-info-background);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  overflow: hidden;
  padding-bottom: 60px;
}

.simple-card:hover {
  transform: translateY(-5px);
}

.simple-card img {
  width: 150px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  text-indent: -9999px; /* Ukrywa tekst alt */
  font-size: 0; /* Dodatkowo zapobiega wyświetlaniu tekstu */
}

.simple-card img[src] {
  display: block; /* Show only when src attribute exists */
}

.simple-card img[alt] {
  color: transparent; /* Ukrywa tekst alternatywny */
}

.simple-card h3 {
  font-size: 1em;
  text-align: center;
  margin: 10px 0;
  color: var(--text-color);
  max-height: 100px; /* Maksymalna wysokość dla nazwy */
  overflow-y: auto; /* Dodajemy scroll jeśli nazwa jest za długa */
  scrollbar-width: thin;
}

.simple-card .quick-info {
  display: flex;
  gap: 15px;
  justify-content: center;
  color: var(--text-color);
}

.simple-card .actions {
  display: flex;
  gap: 10px;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 10px;
  padding: 10px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  background: var(--desktop-info-background);
}

.view-toggle-button {
  background-color: var(--button-background);
  color: var(--button-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  transition: background-color 0.3s;
}

.view-toggle-button:hover {
  background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .simple-view {
    grid-template-columns: 1fr;
    padding: 10px;
  }
.nutrition-info {
    height: 90px;
}
  .simple-card {
    margin: 10px;
    padding-bottom: 70px;
    height:fit-content;
    min-height: 420px; /* Zwiększamy minimalną wysokość na mobile */
  }

  .actions {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 10px 5px;
  }
  
  .actions::-webkit-scrollbar {
    display: none; /* Hide scrollbar for cleaner look */
  }
  .search-link {
    font-size: 20px;
}
.search-link ion-icon {
    font-size: 24px;
    width: 24px;
    height: 24px;
}
}

/* Responsive grid adjustments */
@media only screen and (min-width: 1200px) {
  .simple-view {
    grid-template-columns: repeat(4, 1fr); /* Stała liczba kolumn dla dużych ekranów */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .simple-view {
    grid-template-columns: repeat(3, 1fr); /* Stała liczba kolumn dla średnich ekranów */
    gap: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .simple-view {
    grid-template-columns: repeat(1, 1fr); /* Jedna kolumna dla małych ekranów */
    padding: 10px;
  }
  
  .simple-card {
    width: 100%; /* Full width on mobile */
    max-width: 220px; /* But not wider than 220px */
  }
}

/* Wspólne style dla przycisków */
button {
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
    padding: 8px 16px;
}

/* Style dla przycisku "Zobacz więcej" */
.see-more-btn {
    background-color: var(--button-background);
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    width: auto;
    font-size: 0.9rem;
}

.see-more-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

/* Style dla przycisków akcji */
.actions {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 10px;
    padding: 10px;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    background: var(--desktop-info-background);
}

.actions button {
  min-width: 40px;
  height: 40px;
  padding: 8px;
  font-size: 0.9rem;
  flex-shrink: 0;
}

/* Gluten badge styles */
.gluten-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.8rem;
    color: white;
}

.gluten-free {
    background-color: #4CAF50;
}

.contains-gluten {
    background-color: #f44336;
}

/* Nutrition info styles */
.nutrition-info {
    width: 100%;

    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin: 10px 0;
    min-height: 80px; /* Zapewniamy minimalną wysokość */
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; /* Dodajemy pozycjonowanie */
    flex-shrink: 0; /* Zapobiegamy zmniejszaniu się elementu */
}

.nutrition-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    min-height: 30px; /* Minimalna wysokość wiersza */
    flex-shrink: 0; /* Zapobiegamy zmniejszaniu się elementu */
}

.nutrition-row span {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* View toggle button */
.view-toggle-button {
    margin: 15px auto;
    padding: 12px 24px;
    background-color: var(--button-background);
    color: white;
    font-weight: 500;
    display: block;
}

.view-toggle-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.recipe-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.recipe-link {
  color: #4CAF50;
}

.recipe-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
}

.recipe-btn:hover {
  background-color: #45a049;
}

.product-header {
    position: relative;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

input:focus {
    outline: none; /* Usuwa niebieską ramkę */
}

/* Dodaj nowe style dla statusu "niepewny" */
.uncertain {
    background-color: #ffeb3b; /* Żółty kolor */
    color: black; /* Kolor tekstu */
}

.uncertain-icon {
    color: black; /* Kolor pytajnika */
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    z-index: 1000; /* Upewnij się, że przycisk jest na wierzchu */
}
