@import url('variables.css');

.image-analyzer-container {
  position: relative;
  width: 100%;
  height: 100%; /* Uwzględnia górny i dolny pasek */
  margin-top: 60px; /* Wysokość górnego paska */
  margin-bottom: 80px; /* Wysokość dolnego paska */
  overflow: hidden;
  background: var(--background);
}

@keyframes pop-up {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.pop-up {
  animation: pop-up 0.3s ease forwards; /* Animacja skali i przezroczystości */
}

.webcam-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-analyzer-container {
  position: relative;
  width: 100vw;
}

.circle-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50vmin; /* Adjust size of the circle based on viewport */
  height: 50vmin;
  border: 5px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  pointer-events: none; /* Allows clicks to pass through */
}

.circle-overlay a {
  pointer-events: auto;
}

.top-right-button {
  position: fixed;
  bottom: 30%; /* 80px (bottom-nav) + 90px (bottom-panel) */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 40px;
  background: var(--desktop-info-background);
  color: var(--text-color);
  border-radius: 10px;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, transform 0.3s; /* Added transform transition */
  pointer-events: auto;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 0 15px;
}

.top-right-button:hover {
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.top-right-button svg {
  width: 100%;     /* Dopasowanie szerokości SVG do przycisku */
  height: 100%;    /* Dopasowanie wysokości SVG do przycisku */
}

.circle-text {
  position: absolute;
  top: -70px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  gap: 10px;
}

.bottom-panel {
  position: fixed;
  bottom: 20%; /* 80px dla bottom nav + 10px marginesu */
  left: 0;
  width: 100%;
  padding: 10px;
  background: var(--ui_background);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 999; /* Poniżej bottom-nav (1000) */
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
}

.capture-button, .gallery-upload-button {
  z-index: 1000;
  position: relative;
  margin: 0 10px;
  background: var(--button-background);
  color: var(--button-text-color);
  border: none;
  padding: 15px 30px;
  border-radius: 10px;
  height: 50px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s; /* Added transform transition */
}

.capture-button:hover, .gallery-upload-button:hover {
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.bottom-panel.uploading {
  background: linear-gradient(90deg, rgba(0,0,255,1) 0%, rgba(0,255,255,1) 25%, rgba(0,255,0,1) 50%, rgba(255,255,0,1) 75%, rgba(255,0,0,1) 100%);
  background-size: 400% 400%;
  animation: gradientAnimation 3s ease infinite;
  padding: 10px 0; /* Adjust padding if necessary */
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: var(--text-color);
  border-radius: 50%;
  margin: 0 4px;
  animation: dot-blink 1.4s infinite both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.gallery-upload-button {
  background: var(--button-background);
  color: var(--button-text-color);
  border: none;
  padding: 15px 30px;
  border-radius: 10px;
  width: fit-content;
  height: 50px;
  font-size: 30px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  z-index: 1; /* Ensure button is on top */
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  left: 10px; /* Adjust the right position as needed */
}

.gallery-upload-button:hover {
  background-color: var(--button-hover-background);
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

.gallery-upload-button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

.gallery-upload-input {
  display: none;
}

/* Center the popup on the screen */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure overlay is above other content */
}

/* Make the popup scrollable and centered */
.analysis-popup {
  background: var(--desktop-info-background);
  border-radius: 8px;
  width: 90%; /* Adjust width to be smaller and centered */
  max-width: 400px; /* Limit the maximum width for a smaller popup */
  max-height: calc(100vh - 140px); /* Uwzględnia górny i dolny pasek */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* Add padding for content */
  color: var(--text-color);
  position: fixed;
  top: 60px; /* Wysokość górnego paska */
  left: 0;
  right: 0;
  bottom: 80px; /* Wysokość dolnego paska */
  width: 100%;
  height: auto;
  max-height: calc(100vh - 140px); /* Uwzględnia górny i dolny pasek */
  overflow-y: auto;
  background: var(--desktop-info-background);
  padding: 20px;
  z-index: 1000;
  border-radius: 10px;
  margin: 0 auto;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--text-color);
  transition: color 0.3s;
}

.close-button:hover {
  color: var(--button-background);
}

/* Styling for the table within the popup */
.analysis-details {
  margin-top: 20px;
}

.analysis-details table {
  width: 100%;
  border-collapse: collapse;
}

.analysis-details th, .analysis-details td {
  border: 1px solid #ddd;
  padding: 8px;
}

.analysis-details th {
  background-color: #f4f4f4;
}

.analysis-details td strong {
  font-weight: bold;
}

/* Question section styling */
.question-section {
  margin-top: 20px;
}

.question-section textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.question-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.question-section button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.analysis-details {
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: var(--button-background);
  text-align: left;
}

.question-section {
  margin-top: 20px;
}

.question-section textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.question-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.question-section button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.analysis-details table {
  width: 100%;
  border-collapse: collapse;
}

.analysis-details th, .analysis-details td {
  border: 1px solid #ddd;
  padding: 8px;
}

.camera-controls {
  bottom: 100px;
}

.analysis-details th {
  background-color: var(--button-background);
}

.analysis-details td strong {
  font-weight: bold;
}

.analysis-popup .question-section {
  margin-top: 20px;
}

.meal-details-content {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
}

.meal-summary-section {
  background: var(--desktop-info-background);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-item-dt {
  background: var(--desktop-info-background);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-name-dt {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: var(--text-color);
}

.product-info-table {
  width: 100%;
  border-collapse: collapse;
}

.product-info-table td {
  padding: 8px;
  border-bottom: 1px solid var(--border-color);
}

.ai-disclaimer {
  margin-top: 20px;
  padding: 10px;
  background: var(--desktop-info-background);
  border-radius: 10px;
  font-style: italic;
}

.question-section {
  background: var(--desktop-info-background);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.submit-button {
  background: var(--button-background);
  color: var(--button-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.submit-button:disabled {
  background: var(--button-disabled-background);
  cursor: not-allowed;
}

.meal-ai-name {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 20px;
  padding: 10px;
  background: var(--desktop-info-background);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Chart styling to match MealDetails */


.save-meal-section {
  margin: 20px 0;
  text-align: center;
}

.save-meal-button {
  background: var(--button-background);
  color: var(--button-text-color);
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 200px;
}

.save-meal-button:disabled {
  background-color: var(--button-disabled-background);
  cursor: not-allowed;
}

.saved-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--success-color);
  font-size: 16px;
}

.saved-icon {
  font-size: 24px;
}
