.ideas-container {
  padding: 20px;
}

.goalstitle {
  text-align: center;
  margin-bottom: 20px;
}

.goals {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.goal-tile {
  position: relative;
  width: 200px;
  padding: 20px;
 background-color: #007bff;
 color:white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.goal-tile:hover {
  transform: translateY(-5px);
}

.goal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goal-title {
  font-size: 1.2rem;
  margin: 10px 0;
}

.goal-description {
  font-size: 0.9rem;
  color: white;
}

/* Progress bar container */
.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #f3f3f3;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Progress bar fill */
.progress-bar-fill {
  height: 100%;
  background-color: black;
  width: 0;
  transition: width 0.3s ease;
}

.goal-tile:hover .progress-bar {
  opacity: 1;
}

.goal-tile:hover .progress-bar-fill {
  width: var(--progress-width);
}
