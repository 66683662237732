@import url('variables.css');

.meal-instructions {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll; /* Dodanie scrolla, jeśli zawartość jest zbyt długa */
  padding-top: 50px;
  margin: 20px;
  border-radius: 15px;

  padding: 20px;
  border-radius: 15px; /* Większe zaokrąglenie rogów */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); /* Mocniejszy cień dla elegancji */
  font-family: 'Open Sans', sans-serif; /* Nowoczesna czcionka */
  color: var(--text-color); /* Stonowany kolor tekstu */
}

.meal-instructions ol {
  list-style-type: none; /* Usunięcie domyślnej stylizacji listy */
  padding: 0;
}

.meal-instructions li {
  display: block; /* Użycie flexbox do wyrównania elementów */
  align-items: center; /* Wyśrodkowanie elementów w pionie */
  margin: 20px 0; /* Większy odstęp między elementami */
  background-color: var(--desktop-info-background); /* Białe tło dla każdego elementu listy */
  padding: 15px;
  border-radius: 12px; /* Zaokrąglone rogi elementów */
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.08); /* Subtelny cień */
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.meal-instructions li:hover {
  background-color: #e0f7fa; /* Delikatna zmiana tła po najechaniu */
  transform: scale(1.05); /* Subtelne powiększenie na hover */
}
.h1 {
  color: var(--text-color);
}
.meal-instructions img {
  width: 80px; /* Większa szerokość dla obrazów/ikon */
  height: 80px; /* Ustalenie wysokości, by ikony były kwadratowe */
  margin-right: 20px; /* Większa przestrzeń między obrazem a tekstem */
  border-radius: 50%; /* Obrazy w kształcie koła */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Mocniejszy cień dla ikon */
  background-color: #d1c4e9; /* Delikatny fioletowy kolor tła dla ikony */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Dodanie płynnych przejść */
}

.meal-instructions img:hover {
  background-color: #b39ddb; /* Zmiana koloru ikony po najechaniu */
  transform: scale(1.1); /* Powiększenie ikony na hover */
}

.meal-instructions li span {
  font-size: 18px; /* Większa czcionka dla tekstu */
  font-weight: 500; /* Lekko pogrubiony tekst */
  color: #555; /* Łagodniejszy odcień koloru tekstu */
  line-height: 1.6; /* Większy odstęp między liniami tekstu */
}

.acknowledge-button {
  display: block;
  margin: 20px auto 0 auto; /* Wyśrodkowanie przycisku */
  padding: 12px 24px;
  background-color: var(--button-background, #4CAF50); /* Zielony kolor */
  color: white;
  font-size: 16px;
  font-weight: bold;
  width: 100px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 50px;
}

.acknowledge-button:hover {
  background-color: var(--button-background-dark, #45a049); /* Ciemniejszy odcień zieleni po najechaniu */
}
