/* WelcomeScreen.css */
@import url('variables.css');

.welcome-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centering vertically */
    align-items: center;     /* Centering horizontally */
    width: 100vw;
    height: 100vh;
    background: var(--background) center center;
    background-size: cover;
    padding: 20px;
    box-sizing: border-box;
    color: #fff;
    text-align: center;
    z-index: 1000;
    animation: fadeIn 1s ease-in-out;
}

.blueicon {
    font-size: 2rem; /* Increased icon size */
    color: #007bff;
    transition: color 0.3s ease;
    margin: 0; /* Ensure no extra margins */
    display: block; /* Allow centering */
}

.welcome-title {
    font-size: 2rem;
    font-weight: 700;
    background: var(--desktop-info-background);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
    animation: slideInFromTop 0.8s ease-out;
}

.welcome-subtitle {
    font-size: 1.6rem;
    background-color: var(--desktop-info-background);
    color:var(--text-color);

    margin-bottom: 25px;
    letter-spacing: 0.5px;
    opacity: 0;
    animation: fadeIn 1s ease-in-out 0.3s forwards;
}

.welcome-links {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    max-width: 400px;
    opacity: 0;
    animation: fadeIn 1s ease-in-out 0.6s forwards;
}

.welcome-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    opacity: 0;
    animation: fadeIn 1s ease-in-out 0.9s forwards;
}

.welcome-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.welcome-icon {
   align-self: center;
    color: #007bff;
}

.welcome-text {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
}

.welcome-button {
    align-items: center;
    background-color: #007bff;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    justify-content: center;
    margin: 20px auto;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color .3s, transform .2s;
    width: fit-content;
    animation: fadeIn 1s ease-in-out 1.2s forwards;
}

.welcome-button:hover {
    background: linear-gradient(90deg, #0056b3, #003d99);
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.welcome-card.lifted {
    transform: translateY(-10px); /* Lift the card up */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Enhance shadow when lifted */
}

/* Fade-out animation keyframes */
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
