/* Common styles for both landscape and portrait modes */

@import url('variables.css');

html, body {
  height: 100%;
  width: 100%;
  max-height: 100vh; /* Ensure content does not exceed device height */
  max-width: 100vw; /* Ensure content does not exceed device width */
  margin: 0;
  padding: 0;
   overflow-x: hidden;
  background-color: var(--ui_background);
}

/* body {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
} */


.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: fixed;
  overflow: hidden;
}
.internet-status {
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 1000;
  border-radius:10px;
  padding: 10px 20px;
  color: var(--text-color);
  background-color: var(--desktop-info-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.internet-status .close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  color: var(--button-text-color);
}

@keyframes slideIn {
  0% {
      transform: translateY(20px); /* Przesunięcie w dół */
      opacity: 0; /* Niewidoczny */
  }
  100% {
      transform: translateY(0); /* Powrót do pozycji początkowej */
      opacity: 1; /* W pełni widoczny */
  }
}

/* Klasa do aplikacji animacji */
.fade-in {
  animation: slideIn 1s ease-out; /* Czas trwania 0.5 sekundy */
  will-change: transform, opacity; /* Poprawia wydajność animacji */
}

.quick-shortcut-container {
  position: relative;
  z-index: 1000;
  margin: -30px 0;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: var(--ui_background);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
}

.nav-link {
  text-decoration: none;
  color: #fff;
  padding: 12px 16px; /* More padding for a larger clickable area */
  margin: 0;
  width: 20px; /* Fixed width for consistency */
  height: 20px; /* Fixed height for consistency */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px; /* Softer corners */
  background: rgba(255, 255, 255, 0.1); /* Light overlay for a modern feel */
  transition: transform 0.3s, background 0.3s;
  text-align: center; /* Center align text below icons */
}

.nav-link:hover {
  background: rgba(255, 255, 255, 0.2); /* Slightly brighter on hover */
  transform: translateY(-5px); /* Lift effect */
}

.nav-link .ion {
  font-size: 19px; /* Increase icon size for better visibility */
  margin-bottom: 4px; /* Adjusted space between icon and text */
}

.activeclass {
  background-color: var(--nav-link-bg-active);
  border-radius: 15px; /* Match rounded corners */
  transform: translateY(-5px); /* Lift effect */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for active link */
}

.bottom-nav button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
}







.nav-link .ion {
  margin-bottom: 5px; /* Odległość między ikoną a tekstem */
}



.logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ui_background);
  color: white;
  
 padding-top: calc(var(--safe-area-inset-top) + 10px);
  z-index: 1;
  font-weight: bold;

}

.logo a {
  text-decoration: none;
  color: white;
}

.logo img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius:10px;
}

/* Ensures that the panel covers the full viewport and centers content */
.panel {
  height: calc(100vh - 150px); /* 60px dla górnego paska + 80px dla dolnego */
  width: calc(100vw - env(safe-area-inset-left) - env(safe-area-inset-right)); /* Uwzględnia notche boczne */
  
  overflow-y: auto;
  background-size: cover;
  background-position: center;
  padding: 0;
  display: flex;
  align-items: flex-start; /* Zmienione z center na flex-start */
  justify-content: center;
  position: relative;
  z-index: 1;
overflow-y: hidden;
}

/* Animacja wejścia */


/* Add this to your App.css */





.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}



/* Provides a blurred background overlay */
.overlay {
  height: 100vh; /* Cover the full viewport height */
  width: 100vw; /* Cover the full viewport width */
  overflow: hidden; /* Hide overflow */
  background: var(--background);
   /* Use a CSS variable for background color */
  backdrop-filter: blur(10px); /* Apply blur effect */
  position: absolute; /* Absolute positioning to cover the panel */
  z-index: 0; /* Ensure it is below the panel */
  pointer-events: none;
  background-size: cover;
  /* Prevent interaction with overlay */
}

.modal {
  position: absolute;
  top: var(--header-height); /* Rozpocznij poniżej górnego paska */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 600px;
  max-height: calc(100vh - var(--header-height) - var(--footer-height)); /* Wysokość okna przeglądarki minus wysokości górnego i dolnego paska menu */
  background: var(--desktop-info-background);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow-y: auto; /* Dodaj przewijanie pionowe, gdy zawartość jest zbyt duża */
  margin-top: 5px; /* Dodajemy odstęp od góry */
}

.bottom-nav  {
  height: 80px;
  }
.logo{
  height: 60px;
}
.logo p {
  font-size: 20px;
}

.settings-btn {
  border: none;
color:white;
background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 10px;
}
.refreshicon {
  font-size: xx-large;
  color:white;
}
.refresh-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
 left:20px;
}


/* Hide scanner on devices with screen width between 768px and 1024px */
@media only screen and (min-width: 768px)  {
  .scanner {
    display: none;
  }


}

/* Hide scanner on devices with screen width greater than or equal to 1025px */
@media only screen and (min-width: 1025px) {
  .scanner {
    display: none;
  }
 

}

/* Hide calculator on mobile devices */
@media only screen and (max-width: 767px) {
  .bottom-nav a[href="/app/calc"] {
    display: none;
  }
}

.main-content {
  flex: 1;
  width: 100%;
  height: calc(100vh - 140px); /* 60px for header + 80px for bottom nav */
  padding-top: calc(60px + 5px); /* Dodajemy 5px odstępu od logo */
  padding-bottom: 80px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  margin-top: env(safe-area-inset-top); /* Dodane dla urządzeń z notchem */
  scroll-behavior: smooth; /* Dodane dla płynnego przewijania */
}
