.faq {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
}

.faq h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.faq-item {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.faq-item h3 {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.faq-item h3:hover {
  color: #007bff;
}

.faq-item p {
  margin-top: 5px;
  font-size: 16px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
} 