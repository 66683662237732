@import url('variables.css');

.panel {
  background: var(--background);
  color: var(--text-color);
}

/* Basic styles for meal-details-container */
.meal-details-container {
  background: var(--modal-background);
  color: var(--text-color);
  padding: 20px;
  width: 90%;
  max-height: 60vh; /* Ensure this is the correct height */
  margin: 0 auto;
  text-align: center;
  overflow-y: auto; /* Keep vertical scrolling */
  height: 100%;
  border-radius: 12px;
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
}

/* Header styles for MealDetails */
.meal-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ui_background);
  color: var(--button-text-color);
  padding: 15px;
  height: 80%;
  border-radius: 12px;
  margin-bottom: 20px; /* Space from the rest of the content */
  box-shadow: var(--card-shadow);
}

.product-info-table {
  width: 100%;
  border-collapse: collapse;
  color: var(--text-color);
  margin-top: 10px;
}

.product-info-table th,
.product-info-table td {
  padding: 8px;
  border: 1px solid var(--border-color);
  text-align: left;
}

.product-info-table th {
  background-color: var(--ui_background);
  color: var(--button-text-color);
  font-weight: bold;
}

.product-info-table td:first-child {
  width: 30%;
}

.product-info-table td:nth-child(2),
.product-info-table td:nth-child(3) {
  width: 35%;
}

.meal-total-cu {
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
}

/* Edit button style */
.edit-button, .ai-modify-button {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s, color 0.3s;
  color: var(--button-background);
  font-size: 0.9rem;
  padding: 8px 12px;
}

.edit-button:hover, .ai-modify-button:hover {
  transform: scale(1.1);
  color: var(--highlight-color);
}

.edit-button p, .ai-modify-button p {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
}

/* Draggable item style */
.draggable {
  animation: shake 0.5s infinite;
}

/* Shake animation */
@keyframes shake {
  0%, 100% { transform: translate(0); }
  10% { transform: translate(-2px, 0); }
  20% { transform: translate(2px, 0); }
}

/* Button styles */
.delete-product-button, .add-product-button {
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
  font-size: 16px;
}

.delete-product-button {
  background-color: var(--error-color); /* Use a variable for error color */
  color: white;
  margin-top: 10px;
  width: 100%;
}

.delete-product-button:hover {
  background-color: var(--error-dark-color); /* Darker shade on hover */
  transform: scale(1.05);
}

.add-product-button {
  background-color: var(--button-background);
  color: var(--button-text-color);
  margin: 10px 0;
  width: 100%;
}

.add-product-button:hover {
  background-color: var(--button-hover-color); /* Variable for hover state */
  transform: scale(1.05);
}

/* Summary section */
.meal-details-summary {
  margin: 20px 0;
  font-size: 18px;
  color: var(--text-color);
}

/* Circular summary */
.meal-details-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--background-color);
  border: 5px solid var(--button-background);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--button-background);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Product list */
.meal-details-products {
  text-align: left;
}

.product-item-dt {
  background: var(--desktop-info-background);
  color: var(--text-color);
  padding: 2px;
  height: fit-content;
  margin: 10px 0;
  border-radius: 12px; /* More rounded corners */
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
}

.product-item-dt:hover {
  transform: translateY(-2px); /* Subtle lift effect on hover */
  background: var(--hover-background);
}

.product-item-dt .product-name-dt {
  font-size: 20px;
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color);
}

.product-info {
  gap: 5%;
}

.product-info {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 16px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .meal-details-container {
    height: auto; 
    padding: 15px;
  }

  .meal-details-summary {
    font-size: 16px;
  }

  .circle {
    width: 80px; 
    height: 80px;
    font-size: 20px; 
  }

  .product-item-dt {
    padding: 12px;
    margin: 8px 0;
  }

  .product-name {
    font-size: 16px; 
  }

  .product-info {
    font-size: 14px; 
    flex-direction: column; 
  }

  .edit-button, .ai-modify-button {
    padding: 6px 8px; /* jeszcze mniejszy padding na mobile */
    font-size: 0.8rem; /* mniejsza czcionka na mobile */
  }

  .edit-button p, .ai-modify-button p {
    gap: 2px; /* mniejszy odstęp między ikoną a tekstem */
  }

  .header-buttons {
    gap: 4px; /* mniejszy odstęp między przyciskami */
  }

  /* Zmniejszenie ikon na mobile */
  .edit-button ion-icon, 
  .ai-modify-button ion-icon {
    font-size: 18px;
  }

  /* Dostosowanie nagłówka */
  .meal-details-header {
    padding: 10px;
    flex-wrap: wrap; /* pozwala na zawijanie elementów jeśli nie mieszczą się w jednej linii */
    gap: 8px;
  }

  .title {
    font-size: 1.2rem; /* mniejszy tytuł na mobile */
  }

  .header-left {
    gap: 8px;
  }

  .back-button {
    padding: 6px;
  }
}

.header-buttons {
  display: flex;
  gap: 8px;
}

.recipe-content pre {
  white-space: pre-wrap; /* Umożliwia łamanie linii */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5; /* Ustawia odstęp między liniami */
}

.recipe-container {
  max-width: 80px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

/* Styl dla sekcji przepisu */
.recipe-section {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  background-color: var(--desktop-info-background);
}

.recipe-section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.recipe-section button {
  background-color: var(--button-background);
  color: var(--button-text-color);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.recipe-section button:hover {
  background-color: var(--nav-link-bg-active);
}

.recipe-content {
  max-width: 100%; /* Tekst nie wyjdzie poza rodzica */
  word-wrap: break-word; /* Przełamanie długich słów */
  overflow-wrap: break-word; /* Alternatywa dla lepszej kompatybilności */
  word-break: break-word; /* Przełamanie w miejscach, gdzie to konieczne */
  white-space: normal; /* Pozwól na zawijanie linii */
  overflow: hidden; /* Ukryj tekst, jeśli wystaje poza kontener */
}

/* Editable weight styles */
.editable-weight {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 4px;
  position: relative;
}

.editable-weight:hover {
  background-color: var(--hover-color);
}

.editable-weight:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: var(--tooltip-background, rgba(0, 0, 0, 0.8));
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
}

.weight-edit-container {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  padding: 0;
}

.weight-edit-container input {
  width: 60px;
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--text-color);
}

.weight-input {
  width: 60px;
  padding: 4px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  text-align: center;
}

.weight-input:hover {
  border-color: var(--highlight-color);
}

.weight-input:focus {
  outline: none;
  border-color: var(--highlight-color);
  box-shadow: 0 0 0 2px rgba(var(--highlight-color-rgb), 0.2);
}

.header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button:hover {
  background-color: var(--hover-color);
}

/* Meal summary table */
.meal-summary-table {
  margin: 20px auto;
  max-width: 400px;
}

.meal-summary-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--modal-background);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  color: var(--text-color);
}

.meal-summary-table th,
.meal-summary-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.chart-label {
  font-size: 1.2rem;
  font-weight: bold;
 
  color: var(--text-color);

  margin-bottom: 15px;
}
.meal-summary-table th {
  background-color: var(--ui_background);
  color: var(--button-text-color);
  font-weight: bold;
}

.meal-summary-table tr:last-child td {
  border-bottom: none;
}

/* Empty meal container */
.empty-meal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  text-align: center;
  padding: 20px;
}

.empty-meal-text {
  color: var(--text-color);
  margin: 10px 0 20px 0;
  font-size: 1.1rem;
  opacity: 0.8;
}

.add-product-button-large {
  background: none;
  border: none;
  color: var(--ion-color-primary);
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 0;
}

.add-product-button-large:hover {
  transform: scale(1.2);
}

/* Chart controls */
.chart-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.chart-weight-select {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chart-weight-select:hover {
  border-color: var(--highlight-color);
}

.chart-weight-select:focus {
  outline: none;
  border-color: var(--highlight-color);
  box-shadow: 0 0 0 2px rgba(var(--highlight-color-rgb), 0.2);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .meal-summary-table {
    width:100%;
    overflow-x: auto; /* Włącza przewijanie poziome na urządzeniach mobilnych */
    -webkit-overflow-scrolling: touch; /* Płynne przewijanie na iOS */
  }

  .meal-summary-table th,
  .meal-summary-table td {
    padding: 8px; /* Mniejszy padding na urządzeniach mobilnych */
    font-size: 14px; /* Mniejsza czcionka */
  }

    .chart-container {
      flex-direction: column; /* Zmiana układu wykresu i elementów na kolumnowy na mniejszych ekranach */
    }
  
    .chart {
      width: 100%; /* Pełna szerokość wykresu */
      max-width: none; /* Usunięcie maksymalnej szerokości */
    }
  
 
}
/* Circular summary for chart */
.meal-details-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.meal-details-chart{
  display:flex;
  align-items: center;
}
.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: var(--background-color);
  border: 6px solid var(--button-background);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: var(--button-background);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.circle:hover {
  transform: scale(1.1); /* Powiększenie na hover */
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2); /* Delikatne pogłębienie cienia */
}

/* Chart container */
.chart-container {
  margin: 20px auto;
  padding: 20px;
  background: var(--modal-background);
  box-shadow: var(--card-shadow);
  border-radius: 12px;
  text-align: center;
  max-width: 90%;
  overflow: hidden;
}

.chart-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 15px;
}

.chart-description {
  font-size: 1rem;
  color: var(--text-color-secondary);
  margin-bottom: 20px;
}

.chart-canvas {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 0 auto;
}

/* Legend styles */
.chart-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.chart-legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: var(--text-color);
}

.chart-legend-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--button-background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.meal-details-info {
  width:100%;
  height: fit-content;
}

/* Responsive styles */
@media (max-width: 768px) {
  .circle {
    width: 100px;
    height: 100px;
    font-size: 22px;
  }
.meal-details-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}
  .chart-container {
    padding: 15px;
  }

  .chart-title {
    font-size: 1.2rem;
  }

  .chart-description {
    font-size: 0.9rem;
  }

  .chart-canvas {
    max-width: 300px;
  }

  .chart-legend {
    gap: 8px;
  }

  .chart-legend-item {
    font-size: 0.8rem;
  }

  .chart-legend-color {
    width: 12px;
    height: 12px;
  }
}
