/* styles.css */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
color:rgb(18, 16, 16);
   /* Light border for separation */
  }
  .osicons {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;

    cursor: pointer;
}

.osicon {
    margin-top: 5px; /* Odstęp między tekstem a ikoną */
}

  .text-section {
    max-width: 50%; /* Adjust as needed */
    padding: 20px;
  }
  
  .logo-image {
    width: 150px; /* Adjust based on design needs */
    height: auto;
    margin-bottom: 20px;
  }
  
  .header-title {
    margin: 0;
  }
  
  .header-title h1 {
    font-size: 3rem; /* Adjust size as needed */
    margin: 0;
  }
  
  .header-title .dc {
    color: #007bff; /* Blue color for DiabCalc */
    font-size: 4rem; /* Larger size for emphasis */
  }
  
  .header-description {
    font-size: 1.5rem; /* Subheading text size */
    margin: 0 0 20px;

  }
.bold {
    font-weight: bold;
  }
  .separator {
    border: 0;
    border-top: 1px solid #333; /* Adjust color for a subtle line */
    margin: 20px 0;
  }
  
  .download-title {
    font-size: 1.5rem; /* Adjust size based on design needs */
    margin: 20px 0;
  }
  

  
  .open-web-btn:hover {
    background-color: #0056b3; /* Darker color on hover */
  }
  
  .icon {
    margin-right: 8px;
  }
  
  .image-section {
    max-width: 50%; /* Adjust as needed */
  }
  .blue {
    color:#007bff;
  }
  .family-image {
    width: 100%;
    height: auto;
    border-radius:10px; /* Rounded corners for the image */
  }
  
  .open-web-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-decoration: none; /* Remove underline from links */
    margin: 10px 0; /* Marginesy dla odstępu między przyciskami */
    transition: background-color 0.3s, transform 0.2s; /* Płynna zmiana koloru i efekt powiększenia */
  }
  
  .open-web-btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .open-web-btn a {
    text-decoration: none; /* Ensure no text decoration for links */
    margin: 0; /* Reset margins for links inside the button */
    padding: 0; /* Reset padding for links inside the button */
    color: inherit; /* Inherit color from the parent button */
  }
  
  .disabled-button {
    pointer-events: none;
    filter: grayscale(100%);
  }
  
  /* Responsive Header Styles */
@media (max-width: 768px) {
  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    align-items: center;
    padding: 20px;
    width: 90%; /* Ensure it takes full width on mobile */
    color: rgb(18, 16, 16);
  }
  
  .text-section {
    max-width: 80%;
    text-align: center;
    padding: 10px;
    word-wrap: break-word; /* Ensures text wraps properly within the container */
  }
  
  .logo-image {
    width: 120px; /* Size is small enough for mobile */
  }
  
  .header-title h1 {
    font-size: 2rem; /* Reduced for smaller screens */
    text-align: center;
  }
  
  .header-title .dc {
    font-size: 2.5rem; /* Reduced for better fit */
    text-align: center;
  }
  
  .header-description {
    font-size: 1rem; /* Adjusted for readability */
    text-align: center;
    padding: 0 10px; /* Added padding for breathing space */
    word-wrap: break-word; /* Ensures text wraps within the container */
  }
  
  .separator {
    margin: 15px 0;
  }
  
  .downbtn {
    align-self: center;
    align-content: center;
    width: 100%;
  }
  
  .download-title {
    font-size: 1.5rem;
    text-align: center;
  }
  
  .downloadDesc {
    font-size: 1rem;
    text-align: center;
    padding: 0 10px; /* Added padding for text comfort */
    word-wrap: break-word; /* Ensures text wraps within the container */
  }
  
  .open-web-btn {
    font-size: 1rem;
    padding: 8px 15px;
  }
  
  .image-section {
    max-width: 80%;
    margin-top: 20px;
  }
  

  
}
  @media (max-width: 768px) {
    .header-title h1 {
      font-size: 1.8rem; /* Further reduce on mobile */
    }
  
    .header-title .dc {
      font-size: 2rem; /* Further reduce for better fit */
    }
  
    .header-description,
    .downloadDesc {
      font-size: 0.9rem; /* Reduce to fit better on mobile screens */
    }
  
    .open-web-btn {
      font-size: 0.9rem; /* Button size adjustment */
      padding: 6px 12px; /* Smaller padding for better mobile fit */
    }
  }
@media (max-width: 480px) {
    .header-title h1 {
        font-size: 2rem; /* Even smaller size for very small screens */
    }

    .header-title .dc {
        font-size: 2.5rem; /* Even smaller size for very small screens */
    }

    .header-description {
        font-size: 1rem; /* Ensure readability on very small screens */
    }


    .download-title {
        font-size: 1.2rem;
    }

    .open-web-btn {
        font-size: 0.9rem;
        padding: 6px 12px;
    }
}

/* Dodatkowe style dla przycisku */

.grayed-out {
   background-color: #fb635f;
}
.grayed-out:hover {
  background-color: #ff0800;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.popup-content button {
  background-color: #4a4444a2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #4b45458c;
}

.popup-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.support-btn {
  background-color: #fb635f;
  color: white;
  border: none;
  height:20px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.support-btn:hover {
  background-color: #ff0000;
}

.more-versions-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #666;
  padding: 10px;
  cursor: pointer;
  font-size: 0.9rem;
  margin: 10px auto;
  transition: color 0.3s;
}

.more-versions-btn:hover {
  color: #007bff;
}

.other-versions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #eee;
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .other-versions-container {
    width: 100%;
  }
  
  .more-versions-btn {
    width: 100%;
    justify-content: center;
  }
}
