.product-details-container {
  padding: 1rem;
  max-width: 800px;
  max-height: 60vh;
  margin: 0 auto;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  background: var(--primary-color);
  color: white;
  border-radius: 8px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.product-details-content {
  background: var(--background-color);
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-main-info {
  text-align: center;
  margin-bottom: 2rem;
}

.product-main-info img {
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  margin: 1rem auto;
  display: block;
}

.product-main-info h1 {
  color: var(--text-color);
  margin-bottom: 1rem;
}

.gluten-info {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.gluten-info p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background: var(--secondary-background);
}

.nutrition-section {
  margin: 2rem 0;
}

.energy-info {
  background: var(--secondary-background);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
}

.energy-info p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nutrition-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.nutrition-table th,
.nutrition-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.nutrition-table th {
  background: var(--secondary-background);
  font-weight: bold;
}

.ingredients-section,
.allergens-section {
  margin: 2rem 0;
  padding: 1rem;
  background: var(--secondary-background);
  border-radius: 8px;
}

/* Dark mode adjustments */
.dark-mode .product-details-content {
  background: var(--dark-background);
}

.dark-mode .nutrition-table th {
  background: var(--dark-secondary-background);
}

/* Responsive design */
@media (max-width: 600px) {
  .product-details-container {
    padding: 0.5rem;
  }

  .nutrition-table {
    font-size: 0.9rem;
  }

  .product-main-info img {
    max-width: 100%;
  }

  .nutrition-table th,
  .nutrition-table td {
    padding: 0.5rem;
  }
}

.error-container {
  text-align: center;
  padding: 2rem;
}

.product-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 1rem 0;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 8px;
  background: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.action-button:hover {
  background-color: var(--primary-color-dark);
}

.product-image-container {
  width: 250px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  background: var(--background-color);
}

.product-image-container img {
  width: 350px;
  height: 200px;
  align-self: center;
  object-fit: cover;
  border-radius: 10px;
}

.no-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.no-image-placeholder img {
  width: 64px;
  height: 64px;
  opacity: 0.5;
  margin-bottom: 10px;
}

.no-image-placeholder span {
  color: var(--text-color);
  font-size: 14px;
  text-align: center;
  opacity: 0.7;
}

.portions-selector {
  margin: 15px 0;
  padding: 15px;
  background: var(--background-color);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.portions-selector label {
  font-weight: bold;
  color: var(--text-color);
}

.portions-selector select {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 16px;
  min-width: 120px;
  cursor: pointer;
}

.portions-selector select:hover {
  border-color: var(--primary-color);
}

.portion-selector {
  background: var(--desktop-info-background);
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.portion-selector label {
  font-weight: 600;
  color: var(--button-text-color);
}

.portion-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.portion-selector select {
  padding: 8px;
  border-radius: 6px;
  background: var(--button-background);
  min-width: 150px;
  color: var(--button-text-color);
  cursor: pointer;
}

.custom-portion {
  display: flex;
  align-items: center;
  border: 1px solid var(--text-color);

  padding: 4px;
  border-radius: 6px;
  animation: fadeIn 0.3s ease-in;
}

.custom-portion input {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  width: 80px;
  background: var(--button-background);
  color: var(--button-text-color);
}

.custom-portion span {
  margin-left: 5px;
  color: var(--text-color);
}

.custom-portion-btn {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background: var(--background-color);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-portion-btn:hover {
  background: var(--dark-background);
  color: white;
  border-color: var(--primary-color);
}

.cancel-custom-btn {
  padding: 4px 8px;
  border: none;
  background: transparent;
  color: var(--text-color);
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.cancel-custom-btn:hover {
  opacity: 1;
}

.serving-info, .portions-info {
  margin: 10px 0;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.portions-select {
  margin-top: 5px;
  padding: 5px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.serving-info p, .portions-info p {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}

.serving-info ion-icon, .portions-info ion-icon {
  font-size: 1.2em;
  color: #666;
}


