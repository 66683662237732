

@import url('variables.css');

  .infosettings {
    text-align: center;
    flex-direction: column; /* Elementy będą ułożone kolumnowo */
    align-items: center;
    background: var(--desktop-info-background);
    padding: 20px;
    width: 30%;
    border-radius:10px;
    align-self: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
    position:absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal{
    text-align: center;
    flex-direction: column; /* Elementy będą ułożone kolumnowo */
    align-items: center;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  select {
    width: 25%;
    padding: 10px;
    font-size: 16px;
    border-radius:10px;
    border: 1px solid var(--border-color);
    outline: none;
    background-color: var(--select-bg);
    margin-bottom: 20px;
    color:var(--text-color);
  }
  
  select:focus {
    border-color: #007bff;
    background-color: var(--select-bg);
    color: var(--text-color);
  }
  

  .buttonlogout {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius:10px;
    font-size: large;
    background-color: var(--button-background);
    color: var(--button-text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  


@media only screen and (max-width: 600px) {
  .infosettings{
    width:350px;
  }
}
