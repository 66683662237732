/* variables.css */

:root {
  /* --background:linear-gradient(309deg, rgb(39, 123, 201) 27%, rgba(135,182,222,1) 49%, rgba(245,245,245,1) 79%,rgb(39, 123, 201) 87%); */
--background:url('cukrzyca.jpg');
    --background-color: #ffffff;
    --text-color: #000000;
 --header-height:70px;
 --footer-height:100px;
    --infohome-background: #ffffff;
    --modal-background: #ffffff;
    --desktop-info-background: #f8f8f8;
    --desktop-info-text-color: #333;
    --desktop-info-p-color: #555;
    --button-background: #007BFF;
    --button-text-color: #ffffff;
    --close-color: #aaa;
    --close-hover-color: #000;
    --ui_background:#3498db;
    --nav-link-bg-active: #007BFF;
    --select-bg:#ffffff;
    --border-color:#ccc;
    --mealsup:#20a0f5;
   
  --wwcolor:rgb(0, 0, 0);
  }
  
  body.dark-mode {
  --background: linear-gradient(309deg, rgba(9,157,255,1) 27%, rgba(8,91,147,1) 49%, rgba(23,23,23,1) 79%, rgba(23,23,23,1) 87%);;
    --text-color: #ffffff;
 /* --background:url('cukrzycadark.jpg'); */
    --infohome-background: #2f2f2f;
    --modal-background: #333333;
    --desktop-info-background: #141414;
    --desktop-info-text-color: #ffffff;
    --desktop-info-p-color: #aaaaaa;
    --button-background: #099dff;
    --button-text-color: #ffffff;
    --close-color: #ffffff;
    --close-hover-color: #ffffff;
    --ui_background:#085b93;
    --nav-link-bg-active: #3781d1;
    --border-color:#00000000;
    --select-bg:#099dff;
    --mealsup:#333333;
    --wwcolor:rgb(98, 240, 69);
  }
  