@import url('variables.css');
.register {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px; /* Dostosuj szerokość do swoich potrzeb */
  background: var(--desktop-info-background);
  color:var(--text-color);
  padding: 20px;
  border-radius:10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* Zmieniono kierunek flex na kolumnowy */
  align-items: center;
  max-height: 550px;
}

.modal input {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 20px); /* Szerokość 100% minus marginesy */
  border: none;
  color:var(--text-color);
  border-radius:10px;;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(9, 177, 255, 0.1);
}

.modal button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius:10px;;
  background-color: var(--button-background);
  color: var(--button-text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.modal .change {
  color:var(--ui_background)
}