
.modal .social-media .search-link {
  background-color: var(--button-background);
  color: rgb(255, 255, 255);
  display: flex; /* Ustawia kontener na flexbox */
  align-items: center; /* Wyśrodkowuje zawartość pionowo */
  justify-content: center; /* Wyśrodkowuje zawartość poziomo */
  text-align: center;
  border: none;
  width: 50px;
  height: 50px;
  border-radius:10px;
  transition: background-color 0.3s, color 0.3s;
  margin:0;
  padding:0;
}
.search-link span{
  font-size: 30px;
}


.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 80%;
  max-width: 600px;
  background: var(--desktop-info-background);
  padding: 20px;
  border-radius:10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color:var(--text-color);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 80vh; /* Ograniczenie maksymalnej wysokości do 80% wysokości ekranu */
}

.modal input {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 20px); /* Poprawiłem obliczenia szerokości */
  box-sizing: border-box; /* Dodałem, aby uwzględnić padding w obliczeniach szerokości */
  border: none;
  border-radius:10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(9, 177, 255, 0.1);
  color:var(--text-color);
}

.btnaddproduct {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius:10px;;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Dodatkowy styl dla hover na przycisku */
.btnaddproduct:hover {
  background-color: #0056b3;
}
