/* General styles for the page */
.moderator-application-container-form {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40px 20px;
}

/* Header and Footer */
.moderator-application-form {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

/* Title */
h2.form-title {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
}

/* Form groups */
.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

/* Input and Textarea */
input, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
    color: #333;
    box-sizing: border-box;
}

input:focus, textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

textarea {
    resize: vertical;
    height: 150px;
}

/* Submit button */
.submit-btn {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 1.1rem;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Messages */
.success-message {
    color: green;
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
}

.error-message {
    color: red;
    text-align: center;
    margin-top: 20px;
    font-size: 1.2rem;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.moderator-application-form {
    animation: fadeIn 1s ease-in-out;
}

.moderator-application-container-form {
    animation: fadeIn 1.5s ease-in-out;
}

form {
    animation: fadeIn 1s ease-in-out;
}

/* Mobile responsiveness */
@media (max-width: 1024px) {
    .moderator-application-container-form {
        padding: 30px 20px;
    }

    form {
        padding: 20px;
    }

    h2.form-title {
        font-size: 1.5rem;
    }

    .form-group {
        margin-bottom: 15px;
    }

    input, textarea {
        padding: 10px;
        font-size: 0.9rem;
    }

    .submit-btn {
        font-size: 1rem;
        padding: 10px 15px;
    }
}

/* Tablet and Mobile Sizes */
@media (max-width: 768px) {
    .moderator-application-container-form {
        padding: 20px 10px;
    }

    form {
        padding: 15px;
        max-width: 100%;
    }

    h2.form-title {
        font-size: 1.2rem;
    }

    .form-group {
        margin-bottom: 10px;
    }

    input, textarea {
        padding: 8px;
        font-size: 0.85rem;
    }

    .submit-btn {
        font-size: 1rem;
        padding: 10px;
    }
}

/* Small mobile screens */
@media (max-width: 480px) {
    .moderator-application-container-form {
        padding: 15px;
    }

    form {
        padding: 10px;
        max-width: 100%;
    }

    h2.form-title {
        font-size: 1rem;
    }

    .form-group {
        margin-bottom: 8px;
    }

    input, textarea {
        padding: 8px;
        font-size: 0.8rem;
    }

    .submit-btn {
        font-size: 0.9rem;
        padding: 8px;
    }
}
