@import url('variables.css');

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px; /* Dostosuj szerokość do swoich potrzeb */
  background: var(--desktop-info-background);
  color:var(--text-color);
  padding: 20px;
  border-radius:10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  display: flex;
  flex-direction: column; /* Zmieniono kierunek flex na kolumnowy */
  align-items: center;
  max-height: 550px;
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4285f4; /* Google blue color */
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 20px;
  border-radius: 50px; /* Round edges for a more modern look */
  border: none;
  cursor: pointer;
  width: auto; /* Adjust width to content */
  max-width: 300px; /* Limit maximum width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for modern effect */
  transition: all 0.3s ease-in-out;
  outline: none; /* Remove the default outline */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font */
  text-align: center; /* Center-align text */
  gap: 10px; /* Space between the icon and text */
}

.google-login-button:hover {
  background-color: #357ae8; /* Slightly darker blue for hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.google-login-button:active {
  background-color: #3367d6; /* Even darker blue on active */
  transform: translateY(1px); /* Button looks pressed */
}

.google-login-button svg {
  font-size: 20px; /* Icon size */
}

.google-login-button span {
  display: inline-block;
  text-align: center;
}



.login input {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 20px); /* Szerokość 100% minus marginesy */
  border: none;
  border-radius:10px;
  color:var(--text-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(9, 177, 255, 0.1);
}

.login button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius:10px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Dodano zapytanie media dla małych ekranów */
@media screen and (max-width: 600px) {
  .login {
    width: 90%;
    max-width: 90%;
  }
}
.login .change {
  color:var(--ui_background)
}