@import url('variables.css');

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Wysokość pełnego ekranu */
  text-align: center;
  background-color: var(--modal-background);
  color: var(--text-color);
  width:100vw;
}

.loading-container-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25vh; /* Zmniejszona wysokość */
  width: 25vw; /* Zmniejszona szerokość */
  text-align: center;
font-size: small;
  color: var(--text-color);
}

.loading-icon {
  margin-bottom: 20px;
}

.spin {
  animation: spin 2s linear infinite; /* Animacja obrotu */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
