/* CSS dla całej strony */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

}

.ourteam {
  font-size: 2rem;
  font-weight: bold;

  text-align: center;
  margin-bottom: 30px;
}

.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.member:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.member img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.member h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #444;
  margin-bottom: 10px;
}

.member p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin-bottom: 15px;
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-link {
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}



.audycja {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  transition: color 0.3s ease;
}

.audycja:hover {
  color: #007bff;
}

.icon {
  margin-right: 10px;
}
