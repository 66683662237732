/* Kontener główny */
.newsletter-signup-container {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    gap: 20px;
  }
  
  /* Sekcja obrazu */
  .ns-image-section {
    margin-bottom: 20px;
  }
  
  .newsletter-logo {
    width: 100%;
    max-width: 150px;
    border-radius: 10px;
  }
  
  /* Sekcja tekstowa */
  .ns-text-section {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
  }
  
  .newsletter-title {
    text-align: center;
    color: #333;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .newsletter-description {
    color: #555;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .newsletter-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .subscribe-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .subscribe-button:hover {
    background-color: #0056b3;
  }
  
  .newsletter-message {
    text-align: center;
    margin-top: 20px;
    font-size: 1rem;
  }
  
  .success-message {
    color: #28a745;
  }
  
  .error-message {
    color: #dc3545;
  }
  
  .privacy-note {
    margin-top: 20px;
    text-align: center;
    color: #777;
    font-size: 0.875rem;
  }
  
  /* Animacje */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 1.5s ease-in-out;
  }
  
  /* Responsywność */
  @media (max-width: 768px) {
    .newsletter-signup-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .ns-text-section {
      width: 100%;
      padding: 20px;
    }
  }
  