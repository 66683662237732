.partners-bar {
 
    width: 100%;
    background-color: #2c2f48;
    overflow: hidden;
    padding: 10px 0;
    z-index: 1000;
  }
  
  .scrolling-wrapper {
    display: flex;
    animation: scrollLeft 20s linear infinite;
  }
  
  .partner-logo {
    width: 80px;
    height: 80px;
    margin: 0 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .autopay {
    background-image: url('./files/autopay.png'); /* Replace with the actual path */
  }
  
  .google {
    background-image: url('./files/google.png'); /* Replace with the actual path */
  }
  
  .enea {
    background-image: url('./files/logo-enea.png'); /* Replace with the actual path */
  }
  
  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  