/* General Details Container Styles */
.details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: rgb(18, 16, 16);
}
.media-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .media-container {
    align-items: center;
    text-align: center;

  }

  .media-container p {
    hyphens: none;
  }
  .media-card {
    width: 230px;
    height: 200px;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
/* Media Card - Influ */

/* Sekcja Influencera */
.influencer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.influencer-section h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}



/* Karta Influencera */
.influencer-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    height: 520px;
    margin: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.influencer-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.influencer-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}
.influencer-image img {
    width:20px;
    height:20px;
}
.influencer-info {
    padding: 15px;
}

.influencer-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 10px;
}

.influencer-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
    margin-bottom: 15px;
}

.influencer-button {
    display: inline-block;
    background: #007bff;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1rem;
    transition: background 0.3s ease;
}

.influencer-button:hover {
    background: #0056b3;
}


/* Efekt hover */
.media-card .influ:hover {
    transform: translateY(-10px); /* Unoszenie karty */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); /* Większy cień */
}

/* Obraz w karcie */
.media-card .influ .media-image {
    width: 100%;
    height: 60%; /* Górna część na obraz */
    object-fit: cover; /* Dopasowanie obrazu */
    border-bottom: 2px solid rgba(0, 0, 0, 0.1); /* Delikatny pasek oddzielający */
}

/* Tekst w karcie */
.media-card .influ .media-text {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    text-align: center;
    padding: 15px 10px;
    line-height: 1.5;
}

/* Przycisk w karcie */
.media-card .influ .media-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 15px; /* Odstęp od dolnej krawędzi */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.media-card .influ .media-button:hover {
    background-color: #0056b3; /* Ciemniejszy niebieski */
    transform: scale(1.05); /* Subtelne powiększenie */
}
.partners-container {
  
    align-items: center;
    text-align: center;
}
/* Responsywność */
@media (max-width: 768px) {
    .media-card .influ {
        width: 90%;
        height: auto;
    }
    .media-card .influ .media-image {
        height: 50%;
    }
    .media-card .influ .media-text {
        font-size: 1rem;
        padding: 10px;
    }
    .media-card .influ .media-button {
        width: 80%;
    }
}

  .media-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .media-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .media-image {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 70%;
  }
  
  .media-image.androidowy {
    background-image: url('androidowy.jpg');
  }
  .media-image.mojacukrzyca {
    background-image: url('mojacukrzyca.png');
 background-size: contain;
 background-repeat: no-repeat;
    width:100%;
    height:100%;
  }
  .media-image.autopay {
    background-image: url('./files/autopay.png');
    background-size: contain;
    background-repeat: no-repeat;
       width:100%;
       height:100%;
  }
  .media-image.trzymamcukier {
    background-image: url('./files/trzymamcukier.png');
    background-size: contain;
    background-repeat: no-repeat;
       width:100%;
       height:100%;
  }
  .media-image.enea {
    background-image: url('./files/logo-enea.png');
    background-size: contain;
    background-repeat: no-repeat;
       width:100%;
       height:100%;
  }
  .media-image.google {
    background-image: url('./files/google.png');
    background-size: contain;
    background-repeat: no-repeat;
       width:100%;
       height:100%;
  }
  .media-text {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding: 10px;
    text-align: center;
  }
.app-dt-img-mision {
    width:50%;
    height:auto;
    border-radius: 10px;
}
.app-dt-img {
    width: 100%; /* Adjust image size for tablets */
    height: auto;
    border-radius: 10px;
}
.dt-ld-text-section {
    margin-left: 50px !important;
}
.dt-text-section {
    max-width: 50%;
    padding: 20px;
     /* Space between text and image */
}

.details-title {
    margin-right: 0 !important;
}

.details-title h1 {
    font-size: 3.5rem; /* Large title size for emphasis */
    margin: 0;
}

.details-title .dc {
    color: #007bff; /* Blue color for DiabCalc */
    font-size: 3.5rem; /* Larger size for emphasis */
}
.media-title .dc {
    color: #007bff; /* Blue color for DiabCalc */
    font-size: 3.5rem;
}
.media-title {
    font-size: 3.5rem;
}
.partners{
    font-size: 2rem !important;
  
}
.details-description {
    font-size: 100px !important; /* Subheading text size */
    margin: 0 0 20px;
    
}
.media-description {
    font-size: 20px !important;
}
.details-landing-description {
    font-size: 20px;
}
.video-player {
    width: 100%;
    height: auto;
    display: block;
    cursor: pointer;
}

/* Responsive Styles for Tablets and Small Devices */
@media (max-width: 768px) {
    .iframe {
        width:80%;
       
      }
    .details-container {
        flex-direction: column;
        align-items: center;
        padding: 15px;
    }
    .dt-ld-text-section {
        margin-left: 0px !important;
    }
    .dt-ld-text-section {
        max-width: 80%;
        text-align: center;
        padding: 10px;
    
        word-wrap: break-word; /* Remove margin on smaller screens */
    }
.dt-image-section {
    max-width: 80%;

}
.partners {
    font-size: 2rem !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    width: 100% !important;
    max-width: 100%; /* Dodane */
    padding: 0 15px; /* Dodane */
    box-sizing: border-box; /* Dodane */
}

    .app-dt-img {
        width: 100%; /* Adjust image size for tablets */
        height: auto;
        border-radius: 10px;
    }

    .details-title h1 {
        font-size: 2.5rem; /* Reduce heading size */
    }

    .details-title .dc {
        font-size: 3rem; /* Reduce DiabCalc heading size */
    }

    .details-landing-description {
        font-size: 15px; /* Adjusted for readability */
        text-align: center;
        padding: 0 10px; /* Added padding for breathing space */
        word-wrap: break-word;/* Slightly smaller description for better fit */
    }
}

.honorary-partner {
  grid-column: 1 / -1;
  width: 100%;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.honorary-partner .media-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
}

.honorary-partner .media-text {
  text-align: left;
}

@media (max-width: 768px) {
  .honorary-partner {
    padding: 1rem;
    height: fit-content;
  }

  .honorary-partner .media-link {
    flex-direction: column;
    gap: 1rem;
  }

  .partner-logo-large {
    width: 150px;
  }

  .honorary-partner .media-text {
    text-align: center;
  }

  .honorary-partner h3 {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }

  .honorary-title {
    font-size: 1.2rem;
  }
}

.honorary-partner {
  grid-column: 1 / -1;
  width: 100%;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin-bottom: 2rem;
}

.partner-logo-large {
  width: 200px;
  height: auto;
  margin-bottom: 1rem;
}

.honorary-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  margin: 1rem 0;
}

.honorary-desc {
  font-size: 1.1rem;
  color: #666;
  margin-top: 0.5rem;
}


