.ai-modify-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.shortcut-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.shortcut-btn {
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f5f5f5;
  cursor: pointer;
  font-size: 0.9em;
  transition: background 0.3s;
}

.shortcut-btn:hover {
  background: #e5e5e5;
}

.ai-modify-content form {
  background-color: var(--desktop-info-background);
  color:var(--text-color);
}
.ai-modify-content {
  background-color: var(--desktop-info-background) !important;
  color:var(--text-color) !important;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.ai-modify-content h2 {
  margin-bottom: 15px;
  color: var(--text-color);
}

.ai-modify-content textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
}

.button-group button {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  min-width: 80px;
  height: 32px;
}

.button-group button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.loading-dots .dot {
  width: 4px;
  height: 4px;
  background-color: white;
  border-radius: 50%;
  animation: bounce 0.5s infinite alternate;
}

.loading-dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-4px);
  }
}

.generate-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.generate-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button-group button[type="submit"] {
  background-color: var(--button-background);
  color: white;
}

.button-group button[type="button"] {
  background-color: #6c757d;
  color: white;
} 