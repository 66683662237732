@import url('variables.css');

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Ensure some padding on smaller screens */
}

select {
  width: 25%;
  padding: 10px;
  font-size: 16px;
  border-radius:10px;
  border: 1px solid var(--border-color);
  outline: none;
  background-color: var(--select-bg);
  margin-bottom: 20px;
  color:var(--text-color);
}

select:focus {
  border-color: #007bff;
  background-color: var(--select-bg);
  color: var(--text-color);
}

.grams {
  z-index: 1200;
}

.popup-container {
  background-color: var(--desktop-info-background);
  color: var(--text-color); /* Container background */
  padding: 30px; /* Increased padding for a spacious look */
  border-radius: 12px; /* Slightly more rounded corners */
  width: 100%; /* Full width on smaller screens */
  max-width: 500px; /* Max width for larger screens */
  position: relative; /* Positioning */
  z-index: 1000 !important; /* Above overlay */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Deeper shadow for more depth */
  overflow-y: auto; /* Ensure content is scrollable */
  max-height: 90%; /* Limit height to viewport */
  transition: transform 0.3s ease-in-out; /* Smooth entrance */
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-header h2 {
  margin: 0;
  font-size: 1.6em; /* Increased font size for header */
  color: var(--header-text-color);
}

.close-button {
  background: none;
  border: none;
  font-size: 28px; /* Larger close button */
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--close-button-color);
  transition: color 0.3s, transform 0.3s; /* Added transform for hover effect */
}

.close-button:hover {
  color: var(--close-button-hover-color);
  transform: scale(1.1); /* Slightly enlarges on hover */
}

.popup-body {
  display: flex;
  flex-direction: column;
}

.popup-body h3 {
  margin: 10px 0;
  font-size: 1.4em; /* Increased font size for body headings */
  color: var(--body-text-color);
}

.recommendations {
  display: flex;
  flex-direction: column; /* Stack buttons vertically on smaller screens */
  justify-content: space-around;
  margin-bottom: 20px;
}

.recommendation-button,
.standard-button {
  background-color: var(--button-background);
  color: var(--button-text-color);
  border: none;
  padding: 12px 20px; /* Increased padding for buttons */
  cursor: pointer;
  border-radius: 10px;
  margin: 8px 0; /* Increased margin for spacing */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition effects */
  text-align: center;
  font-size: 1em; /* Font size for readability */
}

.recommendation-button {
  width: 100%; /* Full width on smaller screens */
}

.standard-button {
  width: 90%;
}

.recommendation-button:hover,
.standard-button:hover {
  background-color: var(--button-hover-background);
  transform: scale(1.05); /* Slightly enlarges on hover */
}

/* Media queries for responsiveness */
@media (min-width: 600px) {
  .recommendations {
    flex-direction: row; /* Side by side on larger screens */
    flex-wrap: wrap; /* Allow wrapping */
  }

  .recommendation-button {
    width: 45%; /* 45% width on larger screens */
    margin: 10px; /* Add spacing around buttons */
  }

  .standard-button {
    width: 90%;
  }
}
