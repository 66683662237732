@import url('variables.css');

.bug-report-trigger-button {
  padding: 10px 20px;
  border: 1px solid #ddd;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: medium;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.bug-report-trigger-button:hover {
  background-color: #ff6666;
} 
.popup-container {
    background-color: var(--desktop-info-background);
    color:var(--text-color);
}