.add-quick-shortcut {
  position: relative;
  text-decoration: none;
  color: #fff;
  padding: 12px 16px;
  margin: 0;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s, background 0.3s;
  text-align: center;
}

.add-button {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
}

.options-container {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 180px;
  background: var(--ui_background);
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.option {
  color: white;
  padding: 12px;
  border-radius: 15px;
  cursor: pointer;
  opacity: 0;
  transform: translateY(20px);
  animation: slide-in 0.3s forwards;
  background: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s;
  text-align: left;
  display: flex;
  align-items: center;
}

.option:hover {
  background: var(--nav-link-bg-active);
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.add-quick-shortcut:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-5px);
}
