/* Ogólne style kontenera */

.product-container {
  background-image: url('cukrzyca.jpg');
  width: 100vw;
  height: 100vh;
  display: flex; /* Dodano flexbox dla lepszego układu */
  justify-content: center; /* Wyśrodkowanie zawartości */
  align-items: center; /* Wyśrodkowanie zawartości */
}

.product-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  color: black;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Zwiększono cień dla lepszego efektu */
  border-radius: 10px;
  transition: transform 0.3s; /* Dodano efekt przejścia */
}

.product-details:hover {
  transform: scale(1.02); /* Efekt powiększenia przy najechaniu */
}

/* Tabela produktu */
.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: white;
  color: black;
  border-radius: 5px; /* Dodano zaokrąglenie */
  overflow: hidden; /* Ukrycie nadmiaru */
}

.product-table th, .product-table td {
  padding: 15px;
  text-align: left;
}

.product-table th {
  background-color: #007bff;
  color: white;
  font-size: 16px;
}

.product-table td {
  background-color: #f0f0f0;
  color: black;
}

.product-table .bold {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .product-table th, .product-table td {
    padding: 10px;
    font-size: 14px;
  }
  
  .product-details {
    padding: 15px; /* Zmniejszono padding na mniejszych ekranach */
  }
}

/* Nazwa produktu */
.product-name-shared {
  font-size: 28px;
  color: black;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Ikony */
.ion-icon {
  color: #007bff;
  margin-right: 5px;
  vertical-align: middle;
}

/* Error handling */
.error-container {
  background-color: #f8d7da;
  color: #721c24;
  padding: 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
}

.error-message {
  text-align: center;
  font-size: 18px;
}

/* Ładowanie */
.loading {
  font-size: 20px;
  text-align: center;
  margin-top: 50px;
  color: #007bff;
}

.open-web-btn-shared-container {
  display: flex;
  justify-content: center; /* Wyśrodkowanie w poziomie */
  align-items: center; /* Wyśrodkowanie w pionie */
  gap: 10px; /* Odstęp między elementami */
  flex-wrap: wrap; /* Umożliwia zawijanie przycisków, jeśli nie mieszczą się w jednym wierszu */
  margin: 20px 0; /* Dodano margines górny i dolny dla lepszego odstępu */
}

.open-web-btn-shared {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s; /* Dodano efekt przejścia */
}

.open-web-btn-shared:hover {
  background-color: #0056b3; /* Zmiana koloru przy najechaniu */
}

/* Komunikat section */
.komunikat {
  margin-top: 30px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Dodano cień */
}

.komunikat p {
  font-size: 16px;
  color: black;
  margin-bottom: 10px;
}

.register-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.register-link:hover {
  background-color: #0056b3;
}
