@import url('variables.css');

.admin-panel {
    max-width: 800px;
    width:100vw;
    height:100vh;
    margin: 0 auto;
    padding: 20px;
    background:var(--background);
    font-family: Arial, sans-serif;
  }
  
  .admin-panel h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: var(--text-color);
  }
  
  .applications-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .application-card {
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease;
  }
  
  .application-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .application-card p {
    margin: 5px 0;
    color: #555;
  }
  
  .assign-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .assign-btn:hover {
    background-color: #0056b3;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .admin-panel {
      padding: 10px;
    }
  
    .application-card p,
    .assign-btn {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .admin-panel {
      padding: 5px;
    }
  
    .admin-panel h2 {
      font-size: 1.2em;
    }
  
    .application-card p {
      font-size: 0.85em;
    }
  
    .assign-btn {
      font-size: 0.8em;
      padding: 8px 10px;
    }
  }
  
  .bug-reports-list {
    margin-top: 20px;
  }
  
  .bug-report-card {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .bug-report-card p {
    margin: 5px 0;
  }
  